import { useState, useEffect } from "react";

export const useNetwork = (provider) => {
  const [network, setNetwork] = useState(undefined);

  const updateNetwork = () => {
    const getNetwork = async () => {
      if (!!provider) {
        let network = await provider.getNetwork();
        setNetwork(network);
      }
    };
    getNetwork();
  };

  useEffect(updateNetwork, [provider]);

  return [network, updateNetwork];
};
