const contractListPromise = import("../contracts/contracts.json");
// @ts-ignore
// const externalContractsPromise = import("../contracts/external_contracts");

export const loadNFTContracts = async () => {
  const config = {};
  config.deployedContracts = (await contractListPromise).default ?? {};
  // config.externalContracts = (await externalContractsPromise).default ?? {};
  return config;
};
