import * as cn from "classnames";
import * as styles from "./Articles.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Navigation from "../../components/navigation/Navigation";

//Images
import sa10 from "../../components/images/sa-10.png";
import scientificArticlesBg from "../../components/images/scientific-articles-bg.svg";

//Components
import FooterSmall from "../../components/footer/FooterSmall";
import { ReadThisNext } from "./ReadThisNext";

const Article10 = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          The Interstellar Lightonium Rocket launched from Florida carrying six
          members of A.D.V.I.S.
        </title>
        <meta
          name="description"
          content="Successful Launch of the Interstellar Lightonium Rocket to Galeria"
        />
      </Helmet>
      <Navigation />
      <Container
        fluid
        className={cn(styles.content, "p-0")}
        style={{
          backgroundImage: `url(${scientificArticlesBg})`,
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <Row className={cn("g-0", styles.mainHeader)}>
          <Col xs="8" md="6" className="offset-md-2 offset-2">
            <h6 className="mb-5">
              Successful Launch of the Interstellar Lightonium Rocket to Galeria
            </h6>
            <h4>
              The Interstellar Lightonium Rocket launched from Florida carrying
              six members of A.D.V.I.S.
            </h4>
            <p className={cn(styles.sidebarArticle)}>
              By Sherine Pancer on December 25, 2040
            </p>
          </Col>
          <Col md="2">
            <ReadThisNext offset={0}></ReadThisNext>
          </Col>
        </Row>
        <Row className="g-0">
          <Col md="8" className="offset-md-2">
            <img src={sa10} width="100%" alt="article cover" />
            <p className={cn(styles.sidebarArticle)}>
              Lightonium as it leaves Earth’s atmosphere
            </p>
            <p>
              {" "}
              The Interstellar Lightonium Rocket launched from Florida on
              December 25th, 2040, carrying six members of A.D.V.I.S.-Dr.
              Alejandro Curez, Dr. Charles Obstansa, Marie Odwayo, Professor
              Alfredo Murado, Professor Motoko Kiko, and Sadie O’Harris, along
              with 1,000 pre-embryonic self-opening undefined live sentiants, 50
              Mini Spaceships, and the SpaceX-86 life support system into an
              initial Earth-orbit of 114 by 116 miles.{" "}
            </p>

            <p>
              An estimate says that about 3 billion people watched Galeria’s
              interstellar rocket launch. After three hours, at about 2:49:09,
              the rocket completed one and a half revolutions, moving towards
              the translunar orbit. Second, the I-X-LR reignition stage was
              initiated. At 5:35:45, the I-X-X-LR stage separated and was
              injected into the heliocentric orbit.{" "}
            </p>

            <p>
              The rocket was put on auto-pilot by the OEM members and ADVIS
              members to cover a distance of 39.46 light-years to reach the
              Galeria planet safely and successfully. The ADVIS members were put
              into their sleeper cells for the self-hibernation process, which
              has a time out after 39 years, as planned by the OEM members and
              the SpaceX Salvation-KG team.
            </p>

            <p>Launch Site: Cape Canaveral Air Force Station, Florida</p>

            <p>Launch Vehicle: Interstellar Lightonium Rocket</p>

            <p>Height with SpaceX payload: 235 feet (71.628 metres)</p>

            <p>
              Mass, fully fueled, with Interstellar-X Spacecraft, 50 Mini
              Spaceships, ADVIS pods, SOULS pods: About 3.17 million pounds
              (1437887.81 kilograms)
            </p>
          </Col>
        </Row>
      </Container>

      <FooterSmall />
    </>
  );
};

export default Article10;
