import * as cn from 'classnames';
import * as styles from './Articles.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Navigation from '../../components/navigation/Navigation';

//Images
import sa17 from '../../components/images/sa-17.png';
import scientificArticlesBg from '../../components/images/scientific-articles-bg.svg';

//Components
import FooterSmall from '../../components/footer/FooterSmall';
import { ReadThisNext } from './ReadThisNext';

const Article17 = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Earth Like Planets Found by NASA; Offer Hope for Future of Humanity
        </title>

        <meta
          name="description"
          content="NASA Planet Hunter Team reveals 1st Largest Batch of Earth-size, Habitable-zones, Planets around a Single Star"
        />
      </Helmet>
      <Navigation />
      <Container
        fluid
        className={cn(styles.content, 'p-0')}
        style={{
          backgroundImage: `url(${scientificArticlesBg})`,
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      >
        <Row className={cn('g-0', styles.mainHeader)}>
          <Col xs="8" md="6" className="offset-md-2 offset-2">
            <h6 className="mb-5">
              Earth Like Planets Found by NASA; Offer Hope for Future of
              Humanity
            </h6>
            <h4>
              NASA Planet Hunter Team reveals 1st Largest Batch of Earth-size,
              Habitable-zones, Planets around a Single Star
            </h4>
            <p className={cn(styles.sidebarArticle)}>
              By Jessica Karl Andrew on February 17, 2017
            </p>
          </Col>
          <Col md="2">
            <ReadThisNext offset={0}></ReadThisNext>
          </Col>
        </Row>
        <Row className="g-0">
          <Col md="8" className="offset-md-2">
            <img src={sa17} width="100%" alt="article cover" />
            <p className={cn(styles.sidebarArticle)}>
              Credit: ESO/M. Kornmesser
            </p>

            <p>
              Nasa’s Transiting Exoplanet Survey Satellite (TESS), Kepler Space
              Telescope, and Spitzer’s data discovered the first Earth-size
              planets in the star habitable zone, which are confirmed to have
              the appropriate conditions that might support alien life and
              liquid water on these planets’.
            </p>
            <p>
              Scientists confirmed the data received from the Spitzer and
              ground-based telescopes, which revealed that there are 7
              Earth-size planets in the Trappist-1 system. Researchers and
              NASA’s Science Mission Administration Team named the exoplanet as
              the Trappist-1 system and the following seven planets in
              alphabetical order (a to h) because of its transit observance and
              Planetesimals Small Telescope (Trappist). It was discovered that
              these seven planets might have liquid water on their surfaces –
              which is a necessary key element for human survival.
            </p>
            <p>
              The chances of survival are much better on the four planets
              because they are in the green area of habitable zones. The
              Trappist-1 system revolves around a single star, just like our
              planets in the solar system. It was observed that the Trappist-1
              system is about 40 light-years away from our planet Earth, which
              is approximately 235 trillion miles away.
            </p>
            <p>
              All seven of the planets stack up and revolve in a certain orbit
              around a dwarf star in the Trappist-1 system, just like the
              planets revolve around the sun in our solar system. And the
              Trappist-1e planet stacks up in a certain order so that its
              planetary motion protects its rocky-icy surface, atmosphere, and
              geological structure from getting destroyed.
            </p>
            <p>
              As determined by its density and mass ratio, the Trappist-1e
              planet of the Trappist-1 system is most likely to have a rocky and
              icy surface. The planet might have an Earth-like atmosphere. The
              star of the Trappist-1 system is an ultra-cool dwarf, which means
              it is so very cool as compared to the sun that liquid water can
              easily survive on the surface of the planets that are revolving
              very closely around the star.
            </p>
            <p>
              The seven planets are also very close to each other as compared to
              the planets in our solar system. And if one stood on one of these
              planets to gaze up into the sky, they would surely see the other 6
              planets and their geological features in the sky just like we see
              the moon from the earth’s surface.
            </p>
            <p>
              The Spitzer telescope, which can easily detect infrared trails and
              data, observed the Trappist-1e planet’s architecture and revealed
              that the planet has weather patterns unlike those on Earth, has
              strong winds, and extreme temperature and climate changes during
              day and night.
            </p>
            <p>
              The NASA telescopes and James Webb Telescope also detect the
              fingerprints of water, methane, carbon, and other components in
              the atmosphere of the Trappist-1e planet. And this discovery
              confirms that humans can survive beyond Earth on a habitable
              planet like Trappist-1e, which raises a major question: “Are we
              alone?” and “Do aliens exist?”
            </p>
            <p>
              We believe that the only way to find out the answers to these
              questions is to travel across different planetary systems.
            </p>
          </Col>
        </Row>
      </Container>

      <FooterSmall />
    </>
  );
};

export default Article17;
