import * as cn from 'classnames';
import * as styles from './Galeria.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import Navigation from "../../components/navigation/Navigation";
//SVGS
import galeriaBg1 from '../../components/images/galeria-bg-1.png';
import planet from '../../components/images/galeria01.png';
import planetCore from '../../components/images/planet-core.png';
import temperatures from '../../components/images/temperatures.png';
import topography from '../../components/images/topography.png';
import lunarCalendar from '../../components/images/lunar-calendar.png';
import pressure from '../../components/images/pressure.png';
import heatmap from '../../components/images/heatmap.png';

//Components
import Footer from '../../components/footer/Footer';

const Galeria = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Galeria - Soulmates</title>
        <link rel="canonical" href="http://soulmatesmission.com/galeria" />
        <meta name="description" content="Galeria - SOULMATES" />
      </Helmet>
      <Navigation />
      <Container
        fluid
        className="p-0"
        style={{
          backgroundImage: `url(${galeriaBg1})`,
          backgroundPosition: 'left bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Row className={cn("g-0", styles.mainHeader)}>
          <Col
            md="4"
            className="offset-md-2 d-flex justify-content-center align-items-center"
          >
            <img src={planet} alt="Planet Core" width="100%" />
          </Col>
          <Col md="3">
            <h6 className=" tex-center">GALERIA</h6>
            <p className=" text-left">
              Being 40 light-years away, Trappist-1e / Galeria was too far 
              for the OEM to fully explore prior to sending the SOULS.
            </p>

            <p className=" text-left">
              However, two exploratory probes had been sent, enabling the OEM to
              gather the necessary data to inform the genetic adaptations
              essential for the SOULS’ long-term survival.
            </p>

            <p className=" text-left">
              While Galeria’s atmosphere was similar to Earth’s in that it could
              sustain carbon-based life, it was also more extreme, with a wider
              range of temperatures and harsher environments. Raging rivers of
              ice chunks flow broken off from glaciers that tower above anything
              on Earth. Oceans boil close to Galeria’s equator but become more
              temperate towards the poles.
            </p>
            <p className=" text-left">
              Ancient volcanoes that erupted regularly and carved lava luges
              down their mountainous sides have been re-placed with lush green
              valleys and spring water flowing through the previous molten
              tracks.
            </p>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className={cn('p-0', styles.section)}
        style={{
          backgroundColor: '#FFF',
        }}
      >
        <Row className="g-0">
          <Col
            md="4"
            className={cn('offset-md-4 position-relative', styles.textSection)}
          >
            <h1 className=" text-center">DISCOVERY</h1>
            <p className=" text-center">
              Discovered by Spitzer Space Telescope on 22 February 2017 and
              originally named TRAPPIST-1e or 2MASS J23062928-0502285e.
            </p>

            <p className=" text-center">
              It orbits within the habitable zone around the ultracool dwarf
              star TRAPPIST-1 approximately 40 light-years away from Earth in
              the constellation of Aquarius. We found the exoplanet by using the
              transit method, in which the dimming effect that a planet causes
              as it crosses in front of its star is measured. (Wikipedia)
            </p>
            <h1 className=" text-center">CORE CHARACTERISTICS</h1>
            <img
              src={planetCore}
              alt="Planet Core"
              width="100%"
              className="mb-4"
            />
            <h1 className=" text-center">TEMPERATURES</h1>
            <img
              src={temperatures}
              alt="Planet Temperatures"
              width="100%"
              className="mb-4"
            />
            <h1 className=" text-center">TOPOGRAPHY</h1>
            <img
              src={topography}
              alt="Planet Topography"
              width="100%"
              className="mb-4"
            />
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className="p-0"
        style={{
          backgroundColor: '#2C9CFF',
        }}
      >
        <Row className="g-0">
          <Col
            md="3"
            className={cn(
              'offset-md-3 d-flex flex-column align-items-center justify-content-center',
              styles.textSection
            )}
          >
            <h1 className="text-white text-center">ATMOSPHERE</h1>
            <img src={pressure} alt="Pressure" width="80%" />
          </Col>
          <Col
            md="3"
            className={cn(
              'd-flex flex-column align-items-center ',
              styles.textSection
            )}
          >
            <h1 className="text-white text-center">Lunar Calendar</h1>
            <img src={lunarCalendar} alt="Lunar Calendar" width="80%" className="mb-3" />
            <p className="text-white text-center">
              One full revolution around TRAPPIST-1 takes only 6 Earth days
              (~146 hours) to complete. It orbits at a distance of 0.02928285
              AU, or just under 3% the separation between Earth and the Sun
              (Source: Wikipedia) https://en.wikipedia.org/wiki/TRAPPIST-1e
            </p>
          </Col>
        </Row>
        <Row className="g-0">
          <Col
            md="6"
            className={cn(
              'offset-md-3 d-flex flex-column align-items-center justify-content-center',
              styles.textSection
            )}
          >
            <h1 className="text-white text-center">HEATMAP</h1>
            <img src={heatmap} alt="Heat Map" width="100%" />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Galeria;
