import * as cn from "classnames";
import * as styles from "./Articles.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Navigation from "../../components/navigation/Navigation";

//Images
import sa5 from "../../components/images/sa-5.png";
import sa101 from "../../components/images/sa-10-1.png";
import scientificArticlesBg from "../../components/images/scientific-articles-bg.svg";

//Components
import FooterSmall from "../../components/footer/FooterSmall";
import { ReadThisNext } from "./ReadThisNext";

const Article8 = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Code RED declared for Humanity as Earth’s Expiration Date is
          Determined
        </title>
        <meta
          name="description"
          content="Doomsday is near, and this isn’t fiction, an assumption, or a
          weather forecast, it’s the truth, we have facts that can’t be
          denied"
        />
      </Helmet>
      <Navigation />
      <Container
        fluid
        className={cn(styles.content, "p-0")}
        style={{
          backgroundImage: `url(${scientificArticlesBg})`,
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <Row className={cn("g-0", styles.mainHeader)}>
          <Col xs="8" md="6" className="offset-md-2 offset-2">
            <h6 className="mb-5">
              Code RED declared for Humanity as Earth’s Expiration Date is
              Determined
            </h6>
            <h4>
              Doomsday is near, and this isn’t fiction, an assumption, or a
              weather forecast, it’s the truth, we have facts that can’t be
              denied
            </h4>
            <p className={cn(styles.sidebarArticle)}>
              By Osoman Alvers on July 17, 2030
            </p>
          </Col>
          <Col md="2">
            <ReadThisNext offset={3}></ReadThisNext>
          </Col>
        </Row>
        <Row className="g-0">
          <Col md="8" className="offset-md-2">
            <img src={sa5} width="100%" alt="article cover" />
            <p className={cn(styles.sidebarArticle)}>
              Figure 1: Desert drying up
            </p>
            <p>
              While the rest of the world was dealing with another (white death)
              pandemic, massive heat waves hit Japan, Australia, the
              Philippines, Germany, Pakistan, and North America, causing a
              worldwide emergency. About 1 million animals, including bats,
              horses, dogs, and birds, have been reported dead. About 2 million
              people were affected, and approximately 3 million have been
              reported dead. There was a loss of power and electricity in these
              countries.{" "}
            </p>

            <p>
              Louisiana, Missouri, the USA, Bangladesh, Pakistan, and Malta have
              been hit by the deadliest tornado and thunderstorm; approximately
              50 thousand people have been found dead and more are on the way,
              creating another worldwide emergency.
            </p>

            <p>“We’re approaching the end, not the future.”</p>

            <p>
              The UN and WHO are on their way for addressing the worldwide
              pandemic, heatwave, tornado, and thunderstorm issues, which have
              now become planetary emergency issues, in the New York
              headquarters, while the Indian and Russian governments have come
              forward to provide food, medicine, and supplies to the affected
              countries.
            </p>

            <p>
              “Imagine standing at the centre of the Earth, looking around to
              see sorrow and dearth.”
            </p>

            <p>
              We know from the facts that we have already crossed climate
              tipping points, and this cascade of the deadliest events is a
              warning to human civilization. Heat waves, frequent floods due to
              ocean level rising, landscaping, earthquakes, and volcanic
              eruptions are now a part of daily events. Governments have decided
              to minimize the use of fossil fuels, plastics, and any fuels or
              materials that produce methane in combustion have been banned by
              the government.
            </p>

            <p>
              But scientists have been warning us for decades about these
              situations. Experts say that even if we stop using fossil fuels
              and turn to a renewable source of energy, we still cannot go back
              or undo the things that have been already done. The planet's
              atmosphere, temperature, and pollution are at their peak. We have
              already altered the planetary albedo and atmospheric components.
              And at almost 11 or 12 degrees of warming, about half of the
              current world’s population would die because of direct heat.
            </p>

            <p>
              According to NOAA's (National Oceanic and Atmospheric
              Administration's) last week's report, it has been found that the
              amount of methane concentration in our atmosphere is 1290.8 ppm
              (parts per million), and we know that for humans to survive, only
              1000 ppm is considered safe. Methane concentrations above that can
              replace the oxygen level in our blood, resulting in a severe
              breathing problem or heart problem.
            </p>

            <p>
              "Excess of CO2, ozone, CFCs, and methane, are now eliminating all
              that we should retain."
            </p>

            <p>
              History, ancient dead renominates, dead bodies that were frozen
              and trapped in the Arctic ice have now have been reanimated due to
              climate change and global temperature rise. Creating a situation
              of climate plagues, epidemiologist, biologist, and medicinal
              expert Dr. Khushi Gupta, along with Professor Motoko Kiko of
              ADVIS, have quoted that:
            </p>

            <p>
              "Climate change and temperature rise have led to the melting of
              polar ice, permafrost and Arctic ice, which will release
              prehistoric plagues, anthrax emerging from the permafrost or
              frozen dead body or carcass, bacteria, viruses, remnants of
              ancient flu-like black death or 1918 flu, which killed 1000
              million people, including many other devastating ancient plagues.
              We don't know what viruses we're up against, and it appears that
              more pandemics and epidemics are on the way."
            </p>

            <p>
              "Nature Earth gives us so much to eat while we repay it with the
              scorching heat."
            </p>
            <img src={sa101} width="100%" alt="article cover" />
            <p>
              Let’s talk about food, which is the third basic human need after
              breathable air and clean water. Harvard, MIT, AMITY, IIT, JNU,
              BHU, Oxford, Cambridge, and other top national universities have
              been selected by the government to take care of the agriculture
              sector.
            </p>

            <p>
              We know that Agriculture and farming sectors have been reduced by
              75%, due to the given massive climate change and worldwide blight,
              soils are very infertile, acidic water, poisonous gases in the
              atmosphere, and higher concentrations of methane are not
              supporting certain crops and plantations. Hence, therefore,
              digital farming, agrobots, drones, crop protection, artificial
              fertilizer, GIS software, GPS agriculture, intensive farming,
              gene-edited crop farming, and selective breeding are our most
              reliable options.
            </p>

            <p>
              “History is going to be repeated, humans will become extinct in
              the same way dinosaurs became extinct 252 million years ago.”
            </p>

            <p>
              Geological evidence, experts, and pathologists indicate that
              dinosaurs and other ancient species became extinct because of
              worldwide environmental changes, diseases, ashes and poisonous
              gases from volcanoes suffocating them, food chain imbalances, and
              meteoroid showers or crashing. And out of 10, there are 9
              environmental conditions that perfectly match the past situation
              that was 252 million years ago, when dinosaurs became extinct.{" "}
            </p>

            <p>
              Pollution, global warming, unbreathable air, droughts, rising
              temperatures, heat waves, acid rain, population, flooding,
              landscaping, earthquakes, hurricanes, storms, a higher percentage
              of CO2, methane in our atmosphere, a depleting ozone layer, acidic
              oceans, sudden volcanic eruptions, and other issues are not
              debatable; they are eating up our planet. They are stating and
              screaming that this is just the beginning… that there’s more to
              come…
            </p>

            <p>There are alarming stories in the news every day…</p>

            <p>
              “What have we done to our planet earth, now it’s been too late we
              didn’t realize its worth”
            </p>

            <p>
              People are suffering from natural calamities, plagues, and
              viruses, and yet some of them are buying underground bunkers and
              properties for the coming apocalypse. The government has banned
              many activities which may alter climate conditions, but many
              private companies and manufacturers are still building space
              rockets and underground bunkers for their salvation. If there is
              any business that is glooming these days, it is the real-estate
              business of underground bunkers. But they don’t understand that
              underground bunkers and space rockets will not save them, when the
              planet explodes. They are all just creating chaos in these
              critical situations.
            </p>

            <p>
              We only had one habitable planet, Earth, and we are all on the
              verge of annihilating it...
            </p>

            <p>
              The Mars colonization program infiltrated by Elon Musk and private
              space organization SpaceX is on its halfway, but there isn’t any
              surety or complete chance of this mission being successful.
            </p>

            <p>
              "We are not transforming any planet into a habitable planet;
              rather, we are transforming and turning our planet Earth into a
              ticking time bomb."
            </p>

            <p>
              Our planet is getting sick and dying. The disease is climate
              change caused by humans, and there aren't any antidotes, vaccines,
              or medicines. Hence, it can't be cured.
            </p>

            <p>
              Well said, what's done can't be undone, there will be another
              World War.
            </p>

            <p>Humans v/s climate war, all we can do is surrender...</p>
          </Col>
        </Row>
      </Container>

      <FooterSmall />
    </>
  );
};

export default Article8;
