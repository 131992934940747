import * as cn from 'classnames';
import * as styles from './Footer.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Twitter, Discord, Globe } from 'react-bootstrap-icons';

const FooterSmall = ({ introText }) => {
  return (
    <Container
      fluid
      className={cn('p-0', styles.section)}
      style={{
        backgroundColor: '#FFF',
      }}
    >
      <Row className="g-0">
        <Col md="4" className={cn('offset-md-4', styles.textSection)}>
          <p className="text-black-50 text-center">{introText}</p>
          <div className="d-flex align-items-center justify-content-center mt-5">
            <Globe color="#2C9CFF" size={35} className="mx-3" />
            <Twitter color="#2C9CFF" size={35} className="mx-3" />
            <Discord color="#2C9CFF" size={35} className="mx-3" />
          </div>
        </Col>
      </Row>
      <hr />
    </Container>
  );
};

export default FooterSmall;
