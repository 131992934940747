import * as cn from "classnames";
import * as styles from "./Articles.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Navigation from "../../components/navigation/Navigation";

//Images
import sa7 from "../../components/images/sa-7.png";
import sa71 from "../../components/images/sa-7-1.png";
import scientificArticlesBg from "../../components/images/scientific-articles-bg.svg";

//Components
import FooterSmall from "../../components/footer/FooterSmall";
import { ReadThisNext } from "./ReadThisNext";

const Article13 = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Game Changing Artificial Gestation Technology Passes Final Hurdles
        </title>

        <meta
          name="description"
          content="Artificial insemination techniques produces disease-free and healthy genetic eggs"
        />
      </Helmet>
      <Navigation />
      <Container
        fluid
        className={cn(styles.content, "p-0")}
        style={{
          backgroundImage: `url(${scientificArticlesBg})`,
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <Row className={cn("g-0", styles.mainHeader)}>
          <Col xs="8" md="6" className="offset-md-2 offset-2">
            <h6 className="mb-5">
              Game Changing Artificial Gestation Technology Passes Final Hurdles
            </h6>
            <h4>
              Artificial insemination techniques produces disease-free and
              healthy genetic eggs
            </h4>
            <p className={cn(styles.sidebarArticle)}>
              By Ambers Erikson on April 9, 2034
            </p>
          </Col>
          <Col md="2">
            <ReadThisNext offset={0}></ReadThisNext>
          </Col>
        </Row>
        <Row className="g-0">
          <Col md="8" className="offset-md-2">
            <img src={sa7} width="100%" alt="article cover" />
            <p className={cn(styles.sidebarArticle)}>Sample IVF equipment</p>
            <p>
              In the year 1978, Louise Joy Brown introduced the world to the
              “Test tube baby” technique, in which (IVF) IN-Vitro Fertilisation
              takes place, an Assisted Reproduction Technique (ART).
            </p>

            <p>
              In the year 2016, scientist Hugo Mightaplenis Strange developed an
              artificial uterus as a replacement organ and developed human
              embryos for 13 days only, using an ecto-uterine environment.
            </p>

            <p>
              In the year 2017, fetal scientists and researchers successfully
              grew a premature lamb fetus using an artificial extra-uterine life
              support system at the Children’s Hospital of Philadelphia.
            </p>
            <p>
              Many scientists tried and failed in the ectogenesis process to
              develop a fully functional artificial womb. They were able to
              create an artificial gestation for lambs or sharks, but for human
              babies, they consistently failed.
            </p>

            <p>
              Dr. Sandra Dono, using the IVF technology, ART technique, and
              prior research work of the scientists in ectogenesis
              biotechnology, developed a S.O.U.L.S technology known as Soulmates
              Technology Development.{" "}
            </p>

            <p>
              She used artificial insemination techniques and IVF techniques to
              produce disease-free and healthy genetic eggs, which were
              stimulated using hormone therapy, and then, after that process,
              healthy eggs were retrieved from the ovary (female gamete) and
              healthy sperm (male gamete) cells were chosen for the
              fertilization process.{" "}
            </p>

            <p>
              In a petri dish, those eggs and sperm are combined to form a
              diploid cell, which is known as the fertilization process. And
              after this process, those eggs were injected into an artificial
              womb shell, or chamber, for their development process using
              advanced neonatal unit technology.{" "}
            </p>

            <p>
              For the first time, someone developed a technology in which a baby
              fetus of a human can be gestare outside of a woman’s body. Dr.
              Dono. In the year 2034, she used her technology to fertilize
              nearly 5000 healthy eggs, but only 1,000 of them were chosen for
              the Outlive Earth Movement based on their genetic health and the
              history of their parents’ lifestyle, diet, and diseases.{" "}
            </p>
            <img src={sa71} width="100%" alt="article cover" />
            <p>
              She injected those selected 1,000 diploid fertilized eggs into the
              artificial womb chamber, where the embryonic development phase
              takes place. After two weeks, those 1,000 pre-embryonic cells were
              frozen in their chamber to protect the embryo from being
              destroyed.{" "}
            </p>

            <p>
              The “artificial womb” cells or chambers are outfitted with acrylic
              tanks, plasmatic bags, artificial alive uterine tissues, amniotic
              fluid, and an artificial placenta for the exchange of oxygen and
              waste products, as well as to promote the embryo’s gradual or
              steady growth. Catheter systems, pump systems, extracorporeal
              membrane oxygenation tools, and bio bags were also established in
              those shells.{" "}
            </p>

            <p>
              Those live pre-embryonic cells also underwent the binary conscious
              programme of Dr. Dono. And for their mission to the Galeria
              planet, those 1,000 pre-embryonic live sentiants were named SOULS
              by OEM members. When the SOULS land on the Galeria’s surface, the
              unfreezing process will be activated by ADVIS members, and they’ll
              develop their features, ears, skin, nervous system, spinal cord,
              neural tissue, bones, cartilage, arms, body parts, fetal
              heartbeat, muscles, and other systems in 20 years, depending upon
              the environmental conditions of the planet, using a conscious
              biotechnology development neuro system established in the fetus
              and also inside the artificial womb chamber.{" "}
            </p>

            <p>
              They’ll develop and be mature in such a way that they’ll avoid all
              the steps that will lead to the destruction of their species and
              planet Galeria. They will mate with other beings accordingly to
              avoid any diseases or genetic disorders, leading to a perfect
              match.
            </p>
          </Col>
        </Row>
      </Container>

      <FooterSmall />
    </>
  );
};

export default Article13;
