import * as styles from './SliderCarousel.module.scss';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Image,
  Slide,
  Slider,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Row, Col } from 'react-bootstrap';
import * as cn from 'classnames';

console.log('styles', styles, styles.imageSize)

const SliderCarousel = (props) => {
  const {
    slides,
    total,
    steps,
    visible,
    width,
    height,
    interval,
    buttonsDisabled,
    isSingle,
    isEars,
    itemClassName,
  } = props;

  const onSlideFocused = (data) => {
    console.log('current slide focused', data);
  };

  

  return (
    <CarouselProvider
      visibleSlides={visible}
      interval={interval ?? 2000}
      isPlaying={true}
      lockOnWindowScroll={true}
      infinite={true}
      totalSlides={total}
      step={steps}
      naturalSlideWidth={width}
      naturalSlideHeight={height}
      hasMasterSpinner={true}
      //isIntrinsicHeight={true}
      className={
        isSingle
          ? styles.containerSingle
          : isEars
          ? styles.containerEars
          : styles.container
      }
    >
      <Row className={cn('d-flex justify-content-center align-items-center')}>
        <Col xs="3" md="2">
          {!buttonsDisabled && (
            <ButtonBack className={cn(styles.button)}>
              <img
                src={'/souls/icon_back.png'}
                alt="Previous"
                width={isSingle ? '100%' : '50%'}
                className="mb-4"
              />
            </ButtonBack>
          )}
        </Col>
        <Col xs="6" md="8">
          <Slider>
            {slides.map((item, index) => (
              <Slide
                index={index}
                onFocus={() => onSlideFocused()}
                //className={styles.slide}
                innerClassName="slider-inner-class"
                classNameVisible={styles.slide_is_visible}
              >
                <Image src={item} alt={item} className={cn(styles.imageSize, itemClassName)} />
              </Slide>
            ))}
          </Slider>
        </Col>
        <Col xs="3" md="2">
          {!buttonsDisabled && (
            <ButtonNext className={cn(styles.button)}>
              <img
                src={'/souls/icon_forward.png'}
                alt="Next"
                width={isSingle ? '100%' : '50%'}
                className="mb-4"
              />
            </ButtonNext>
          )}
        </Col>
      </Row>
    </CarouselProvider>
  );
};

export default SliderCarousel;
