import * as cn from "classnames";
import * as styles from "./ExpeditionTeam.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Navigation from "../../components/navigation/Navigation";

//SVGS
import expeditionTeamBg1 from "../../components/images/expedition-team-bg-1.png";
import expeditionTeamBg2 from "../../components/images/expedition-team-bg-2.png";
import drSandraDonoRounded from "../../components/images/dr-sandra-dono-rounded.png";
import professorAlfredoMuradoRounded from "../../components/images/proffesor-alfredo-murado-rounded.png";
import drCharlesObstansaRounded from "../../components/images/dr-charles-obstansa-rounded.png";
import sadieOHarrisRounded from "../../components/images/sadie-o-harris-rounded.png";
import proffesorMotokoKikoRounded from "../../components/images/proffesor-motoko-kiko-rounded.png";
import marieOdwayoRounded from "../../components/images/marie-odwayo-rounded.png";
import drAlejandroCurezRounded from "../../components/images/dr-alejandro-curez-rounded.png";

//Components
import Footer from "../../components/footer/Footer";

const ExpeditionTeamMember = (props) => {
  const { img_src, img_alt, brief_bio, long_bio } = props;

  return (
    <>
      <Row className="g-0">
        <Col md="1" className={cn("offset-md-3", styles.expedition_headshot)}>
          <img src={img_src} alt={img_alt} className="" width="100%" />
        </Col>
        <Col md="5" className="ms-4">
          {brief_bio}
        </Col>
      </Row>
      <Row className="g-0">
        <Col md="6" className="offset-md-3">
          {long_bio}
        </Col>
      </Row>
    </>
  );
};

const ExpeditionTeam = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Expedition Team - Soulmates</title>
        <link
          rel="canonical"
          href="http://soulmatesmission.com/expedition-team"
        />
        <meta name="description" content="Expedition Team - SOULMATES" />
      </Helmet>
      <Navigation />
      <Container
        fluid
        className={cn(styles.content, "p-0")}
        style={{
          backgroundImage: `url(${expeditionTeamBg1})`,
          backgroundPosition: "left bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <h6 className="text-center mb-5">EXPEDITION TEAM</h6>
        <ExpeditionTeamMember
          img_alt="Dr Sandra Dono Profile"
          img_src={drSandraDonoRounded}
          brief_bio={
            <p>
              <span className={cn(styles.title)}>Dr. Sandra Dono</span>
              <br />
              <span className={cn(styles.title)}>Head of O.E.M.</span>
              <br />
              DOB: 5/09/2005
              <br />
              Age: 35
              <br />
              Undergraduate: Stanford (2025)
              <br />
              Graduate: PhD, Neuro-Cognition, MIT (2030)
            </p>
          }
          long_bio={
            <>
              <p>
                Dr. Sandro began her research in the year 2026. While a grad
                student at Stanford, she worked on several cognitive
                neuroscience brain-split projects.
              </p>

              <p>
                She joined MIT to pursue a PhD in Neuro-cognition, including the
                SAGE center program (2030). Through a break-through, a creative
                and innovative competition conducted by NASA and NIA gained
                entrance into the elite ex-traplanetary exploration Marsboreal
                Greenhouse Project, established in 2019 and has since become the
                preemi-nent post-grad program for people interested in
                contributing to the Outlive Earth Movement (OEM).{" "}
              </p>

              <p>
                She discovered a novel concept of a binary representation of
                humankind and conscious transfer and was rewarded with two Nobel
                prizes for this discovery. Later, she was appointed as the Head
                of OEM by President Eliza Sonders-Rolins and NASA. Dr. Sandro
                also received several awards for her neuro-cognitive research,
                tangible psy-chological theories, and ground-breaking conscious
                transfer technology by the Inter-National Science Community.{" "}
              </p>

              <p>
                Dr. Sandro Dono was officially selected by NASA in the year 2026
                and was handed over the crucial responsibility of saving
                humankind through OEM. She came up with the collective transfer
                theory while completing her academic thesis during her PhD
                years. She initiated the team A.D.V.I.S (automated Distributed
                Visionary Information System) and 1,000 pre-embryonic
                self-opening undefined live sentients (S.O.U.L.S.) projects to
                save the last bit of humanity and humankind.
              </p>
            </>
          }
        />
      </Container>
      <Container fluid className={cn(styles.content, "p-0")}>
        <ExpeditionTeamMember
          img_alt="Professor Alfredo Murado Profile Rounded"
          img_src={professorAlfredoMuradoRounded}
          brief_bio={
            <p>
              <span className={cn(styles.title)}>Professor Alfredo Murado</span>
              <br />
              <span className={cn(styles.title)}>Chief Engineer, ADVIS</span>
              <br />
              DOB: 9/03/1992
              <br />
              Age: 48
              <br />
              Undergraduate: California Institute of Technology (2013)
              <br />
              Graduate: PhD, Engineering, MIT (2020)
              <br />
              <span className={cn(styles.title)}>
                Specialties: Mechanical engineering, nuclear fusion{" "}
              </span>
            </p>
          }
          long_bio={
            <>
              <p>
                Professor Alfredo Murado was the first person selected by the
                OEM team to undertake the “Conscious Transfer” technology for
                the A.D.V.I.S mission. Alfredo completed his bachelor’s from the
                California Institute of Technology (2013) and completed his
                Master’s/PhD from MIT (engineering) in 2020.
              </p>

              <p>
                In 2021, NASA selected Alfredo for the MARS JK-999 Exploration
                Program. Alfredo designed the most powerful rockets and
                instruments for deep space exploration to observe the atmosphere
                of a far planet during his college years. He was awarded the
                Drucker Medal, the Bessemer Gold Medal, and the British
                Engineering Excellence Award for his advanced automated thermal
                space rockets with their outstanding engineering design and
                innovative contribution to the field of applied mechanics.
              </p>

              <p>
                Alfredo was selected for the ADVIS mission to
                Galeria/Trappist-1e because of his extraordinary work and
                expertise in the engineering field and nuclear fusion. He was
                appointed to a chief engineer post as decided by the OEM team
                and NASA. Alfredo has completed four space flight missions
                totaling 546 days, including STS919-XNAS, which was co-conducted
                by SpaceX and NASA.
              </p>
            </>
          }
        />
      </Container>
      <Container fluid className={cn(styles.content, "p-0")}>
        <ExpeditionTeamMember
          img_src={drCharlesObstansaRounded}
          img_alt="Dr Charles Obstansa Profile Rounded"
          brief_bio={
            <p>
              <span className={cn(styles.title)}>Dr. Charles Obstansa</span>
              <br />
              <span className={cn(styles.title)}>Commander, ADVIS</span>
              <br />
              DOB: 8/05/1996
              <br />
              Age: 44
              <br />
              Undergraduate: Georgia Institute of Technology (2016)
              <br />
              Graduate: MD, Stanford (2022)
              <br />
              <span className={cn(styles.title)}>
                Specialties: Aeronautical engineering, medicine, biochemistry
              </span>
            </p>
          }
          long_bio={
            <>
              <p>
                Dr. Charles Obstansa was selected by NASA in the year 2023. He
                completed his bachelor’s in aeronautical engi-neering from the
                Georgia Institute of Technology (2016). The Washington native
                also earned an MD in medicine and a doctorate in biochemistry
                from Stanford University in 2022.
              </p>

              <p>
                He was appointed as a Commander for Duty in August 2023 by NASA
                for the NASA SpaceXCrew99 mission to the International Solar
                System, which launched on November 25th, 2023. Dr. Charles has
                served as a commander for more than 400 combat and space
                operations and has been awarded three golden stars, two silver
                stars, and one bronze star with Combat “K.”
              </p>

              <p>
                Due to his expert knowledge, qualifications, and interstellar
                mission experience, the OEM team and NASA selected him for the
                A.D.V.I.S mission to the exoplanet Galeria/Trappist-1e. He was
                appointed as commander for the ADVIS mission to Galeria.
              </p>
            </>
          }
        />
      </Container>
      <Container fluid className={cn(styles.content, "p-0")}>
        <ExpeditionTeamMember
          img_src={sadieOHarrisRounded}
          img_alt="Sadie O Harris Profile Rounded"
          brief_bio={
            <p>
              <span className={cn(styles.title)}>Sadie O’Harris</span>
              <br />
              <span className={cn(styles.title)}>Astrobotanist</span>
              <br />
              DOB: 08/17/2008
              <br />
              Age: 32
              <br />
              Undergraduate: Columbia (2026)
              <br />
              Graduate: Masters, Hydroponics, University of Michigan (2027)
              <br />
              Graduate: PhD, Bioastronautics, Harvard-MT (2030)
              <br />
              <span className={cn(styles.title)}>
                Specialties: Water Recycling, Astrobiology, Astrobotany Lead,
                GreenHab at Mars Desert Research Station (Utah)
              </span>
            </p>
          }
          long_bio={
            <>
              <p>
                Sadie O’Haris was selected by SpaceX for the 2031
                Perseverance-Ingenuity-Human-Exploration-X21 mission. She has
                completed her Master’s in Hydroponics from the University of
                Michigan in the year 2027 and has also earned a PhD in
                Bioastronautics from Harvard-MT in the year 2030.{" "}
              </p>

              <p>
                Sadie O’Haris has more than five years of training as an
                astro-botanist candidate in outer space. She was in the on-lead
                position on the mission Mars Desert Research Station
                (Utah)-GreenHab. Sadie O’Haris conducted her grad-uate research
                on the abiotic organic molecules formed in star regions and
                rocky-gusty planets of the Trappist-1e system using Spitzer’s
                data and James Webb Space Telescope’s data.{" "}
              </p>

              <p>
                She observed the Hubble team’s, Spitzer’s, and James Webb Space
                Telescope’s data and analytics and revealed that the planets of
                Trappist-1e have an atmosphere that is 70% similar to the
                Earth’s atmosphere. She has also worked in an ISS program
                initiated by NASA for life support management and water
                recycling during her college years and came up with the
                innovative idea of building a bio-tech-chemical plant that
                extracts the hydrogen that is found in the atmosphere and treats
                it with chemical plants and oxides to generate liquid water.
              </p>

              <p>
                She was appointed to the position of astro botanist for the
                ADVIS mission to Galeria by the OEM and NASA.{" "}
              </p>
              <p>
                Professor Motoko Kiko was selected by NASA in the year 2035. She
                completed her bachelor’s in 2026 from Cornell University and her
                Master’s in Philosophical Theology from Oxford University. She
                also earned a PhD/MD in psy-chology and medicine, respectively,
                from Harvard in the year 2034.{" "}
              </p>

              <p>
                She was selected by Nasa (2035) for the
                Survival-Human-Expedition-99-45/54 program.
              </p>
            </>
          }
        />
      </Container>
      <Container
        fluid
        className={cn(styles.content, "p-0")}
        style={{
          backgroundImage: `url(${expeditionTeamBg2})`,
          backgroundPosition: "left bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <ExpeditionTeamMember
          img_src={proffesorMotokoKikoRounded}
          img_alt="Professor Motoko Kiko Profile Rounded"
          brief_bio={
            <p>
              <span className={cn(styles.title)}>Professor Motoko Kiko</span>
              <br />
              <span className={cn(styles.title)}>Psychologist</span>
              <br />
              DOB: 06/16/2006
              <br />
              Age: 34
              <br />
              Undergraduate: Cornel (2026)
              <br />
              Graduate: Masters, Philosophical Theology, University of Oxford
              Graduate: PhD/MD, Psychology/Medicine, Harvard (2034)
              <br />
              <span className={cn(styles.title)}>
                Specialties: Psychotherapy, Adaptation, Communication,
                Meditation, Medicine
              </span>
            </p>
          }
          long_bio={
            <>
              <p>
                {" "}
                Professor Motoko Kiko has worked on the Earth
                Cimatic-Scenario-78 project with famous scientist Dr. Khushi
                Gupta and has commented upon Earth’s critical ending situation.
                She was the first person who sequenced and structured artificial
                conscious DNA in space.{" "}
              </p>

              <p>
                She has expertise in topics like psychotherapy, adaptation,
                communication, meditation, and medicine. She was awarded the
                Bigelow Aerospace and Ansari X Prizes for her outstanding work
                and research in 2036. Motoko served at the International Space
                Centre for the SpaceX-99-Mars exploration mission.{" "}
              </p>

              <p>
                She has more than 5 years of training in flight surgery and
                space flight medicine practice. She has more than seven years of
                spaceflight experience. Motoko Kiko has written several theses
                on psychotherapy, psychological therapies, and advanced space
                medicine, which were rewarded by UNESCO. Professor Motoko Kiko
                was selected for the psy-chologist position for the ADVIS
                mission to the Galeria/Trappist-1e by the OEM team and NASA.
              </p>
            </>
          }
        />
      </Container>
      <Container fluid className={cn(styles.content, "p-0")}>
        <ExpeditionTeamMember
          img_src={marieOdwayoRounded}
          alt="Marie Odwayo Profimg_alt=Rounded"
          brief_bio={
            <p>
              <span className={cn(styles.title)}>Marie Odwayo </span>
              <br />
              <span className={cn(styles.title)}>Deputy Commander</span>
              <br />
              DOB: 06/25/2003
              <br />
              Age: 47
              <br />
              Undergraduate: Northwestern (2024)
              <br />
              Graduate: Masters, Artificial Intelligence, Carnegie Mellon
              University
              <br />
              <span className={cn(styles.title)}>
                Specialties: Computer engineering, Bio-organic interfaces,
                Augmented reality
              </span>
            </p>
          }
          long_bio={
            <>
              <p>
                Marie Odwayo was selected by NASA in 2029. She completed her
                bachelor’s at Northwestern in the year 2024. She also holds a
                Master’s degree in Artificial Intelligence from Carnegie Mellon
                University (2026).
              </p>

              <p>
                She began her professional career at the Johnson Space Centre
                (JSC) in the year 2026 as an engineer and deputy commander for
                the international space shuttle program. She has more than five
                years of training and experience as an AI flight engineer.{" "}
              </p>

              <p>
                She has written for several academic journals and has expertise
                in topics like computer engineering, bio-organic interfaces, and
                augmented reality. In the year 2029, Marie developed an
                AlphaBetaFold9 program that revealed
                protein-folding-molecules-problems.{" "}
              </p>

              <p>
                In 2032, Marie came up with robots and automated software named
                Tars-X and Case-Y to assist astronauts or engi-neers on the
                International Space Station. Marie Odwayo was awarded the Nobel
                Prize, the Turning Award, and the Turning Talk Award in 2033 for
                her fantastic robotic creation and work in machine learning,
                neural networks, AI, object recognition, and cognitive science.{" "}
              </p>

              <p>
                She has worked as an AI engineer, flight engineer, and deputy
                commander with JSC, NASA, and SpaceX. She was appointed as a
                Deputy Commander for the ADVIS mission to the
                Galeria/Trappist-1e by the OEM team and NASA.
              </p>
            </>
          }
        />
      </Container>
      <Container fluid className={cn(styles.content, "p-0")}>
        <ExpeditionTeamMember
          img_src={drAlejandroCurezRounded}
          img_alt="Doctor Alejandro Curez Profile Rounded"
          brief_bio={
            <p>
              <span className={cn(styles.title)}>Dr. Alejandro Curez</span>
              <br />
              <span className={cn(styles.title)}>Specialist</span>
              <br />
              DOB: 12/27/2002
              <br />
              Age: 48
              <br />
              Undergraduate: University of São Paulo (2024)
              <br />
              Graduate: PhD, Geosciences, Princeton (2032)
              <br />
              <span className={cn(styles.title)}>
                Specialties: Geology, Topography, Cartography, Survival skills,
                Meteorology
              </span>
            </p>
          }
          long_bio={
            <>
              <p>
                Dr. Alejandro Curez was selected by NASA in the year 2032. Dr.
                Alejandro completed his bachelor’s at the Univer-sity of São
                Paulo in the year 2024. He holds a PhD in Geosciences, completed
                at Princeton in the year 2032.
              </p>

              <p>
                He has written several academic journals and theses on topics
                focused primarily on geology, topography, cartog-raphy,
                meteorology, and survival skills. Dr. Alejandro revealed in his
                thesis that the exoplanet Trappist-1e/Galeria doesn’t have any
                hydrogen-dominant atmospheres, which indicates the planet’s
                compatibility and hydrogen-free atmosphere during his college
                years.{" "}
              </p>

              <p>
                He also revealed in his 2027 journal that the exoplanet
                Trappist-1e/Galeria has transiting geological and atmos-pheric
                conditions. His research, academic journals, and thesis were
                appreciated and awarded by the Geological Society of America
                with the Arthur L. Day Medal and the G. K. Gilbert Award. He was
                selected by NASA in the year 2032 for the NASA SpaceX Crew-99
                mission to the ISS (international space station). He has more
                than 3 years of space flight experience.{" "}
              </p>

              <p>
                Dr. Alejandro has expertise in understanding climate or
                atmospheric patterns, temperature variations, and the planet’s
                atmosphere. He was selected by SpaceX for the “Lost, Found, and
                Habitable” project in the year 2025.{" "}
              </p>

              <p>
                Dr. Alejandro Curez was appointed to the Specialist position for
                the ADVIS mission to the exoplanet Ga-leria/Trappist-1e by the
                OEM team and NASA.
              </p>
            </>
          }
        />
      </Container>

      <Footer />
    </>
  );
};

export default ExpeditionTeam;
