import * as cn from 'classnames';
import * as styles from './ScientificArticles.module.scss';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Navigation from '../../components/navigation/Navigation';

//Images
import sa1 from '../../components/images/sa-1.png';
import sa2 from '../../components/images/sa-2.png';
import sa3 from '../../components/images/sa-3.png';
import sa4 from '../../components/images/sa-4.png';
import sa5 from '../../components/images/sa-5.png';
import sa6 from '../../components/images/sa-6.png';
import sa7 from '../../components/images/sa-7.png';
import sa8 from '../../components/images/sa-8.png';
import sa9 from '../../components/images/sa-9.png';
import sa10 from '../../components/images/sa-10.png';
import sa11 from '../../components/images/sa-11.png';
import scientificArticlesBg from '../../components/images/scientific-articles-bg.svg';

//Components
import FooterSmall from '../../components/footer/FooterSmall';

const ScientificArticles = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Scientific Articles - Soulmates</title>
        <link rel="canonical" href="http://soulmates.com/scientific-articles" />
        <meta name="description" content="Scientific Articles - Soulmates" />
      </Helmet>

      <Navigation />
      <Container
        fluid
        className={cn(styles.content, 'p-0')}
        style={{
          backgroundImage: `url(${scientificArticlesBg})`,
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      >
        <h6 className="text-center mb-5">SCIENTIFIC ARTICLES</h6>
        <Row className="g-0">
          <Col md={8} className="offset-md-2">
            <h4 className=" mb-5">RESEARCH ABSTRACTS</h4>
          </Col>
        </Row>
        <Row className={cn('g-0', styles.mainHeader)}>
          <Col md="4" className="offset-md-2">
            <Nav.Link
              className={styles.article_link}
              href="scientific-articles/15/Brain-inspired-cognitive-systems"
            >
              <img src={sa1} width="100%" alt="article cover" />
              <h5>Brain-inspired cognitive systems</h5>
              <p>
                Artificial intelligence (AI) has gone under indispensable
                advancements to further the autonomous AI robotics which
                provides the frameworks for the actions and decision-making of
                AI systems
              </p>
              <p className={cn(styles.subtext)}>
                By Marie Odwayo on December 2, 2026
              </p>
            </Nav.Link>
          </Col>
          <Col md="4">
            <Nav.Link
              className={styles.article_link}
              href="scientific-articles/16/Bioplotting-for-vestigial-embryonic-development"
            >
              <img src={sa2} width="100%" alt="article cover" />
              <h5>Bioplotting for vestigial embryonic development</h5>
              <p>
                3D-bioprinting has emerged as a potential approach to advance
                the medical field
              </p>
              <p className={cn(styles.subtext)}>
                By Sandra Dono on October 1, 2030
              </p>
            </Nav.Link>
          </Col>
        </Row>
        <Row className="g-0">
          <Col md={8} className="offset-md-2">
            <h4 className=" mb-5">ARTICLES</h4>
          </Col>
        </Row>
        <Row className={cn('g-0', styles.mainHeader)}>
          <Col md="4" className="offset-md-2">
            <Nav.Link
              className={styles.article_link}
              href="scientific-articles/17/Earth-Like-Planets-Found-by-NASA"
            >
              <img src={sa3} width="100%" alt="article cover" />
              <h5>
                Earth Like Planets Found by NASA; Offer Hope for Future of
                Humanity
              </h5>
              <p>
                NASA Planet Hunter Team reveals 1st Largest Batch of Earth-size,
                Habitable-zones, Planets around a Single Star
              </p>
              <p className={cn(styles.subtext)}>
                By Jessica Karl Andrew on February 17, 2017
              </p>
            </Nav.Link>
          </Col>
          <Col md="4">
            <Nav.Link
              className={styles.article_link}
              href="scientific-articles/12/OEM-Embroiled-In-Latest-Controversy-After-Mysterious-Murder"
            >
              <img src={sa4} width="100%" alt="article cover" />
              <h5>
                OEM Embroiled In Latest Controversy After Mysterious Murder
              </h5>
              <p>
                OEM under the inspection over disappearance of Greenpeace
                activist
              </p>
              <p className={cn(styles.subtext)}>
                By Barbara Canvers on August 1, 2028
              </p>
            </Nav.Link>
          </Col>
        </Row>
        <Row className={cn('g-0', styles.mainHeader)}>
          <Col md="4" className="offset-md-2">
            <Nav.Link
              className={styles.article_link}
              href="scientific-articles/8/Code-RED-declared-for-Humanity-as-Earth’s-Expiration-Date-is-Determined"
            >
              <img src={sa5} width="100%" alt="article cover" />
              <h5>
                Code RED declared for Humanity as Earth’s Expiration Date is
                Determined
              </h5>
              <p>
                Doomsday is near, and this isn’t fiction, an assumption, or a
                weather forecast, it’s the truth, we have facts that can’t be
                denied
              </p>
              <p className={cn(styles.subtext)}>
                By Osoman Alvers on July 17, 2030
              </p>
            </Nav.Link>
          </Col>
          <Col md="4">
            <Nav.Link
              className={styles.article_link}
              href="scientific-articles/9/Winner-of-Competition-for-the-New-Name-of-TRAPPIST-1E-Named"
            >
              <img src={sa6} width="100%" alt="article cover" />
              <h5>
                Winner of Competition for the New Name of TRAPPIST-1E Named
              </h5>
              <p>
                The contest called on the youngest generations to take the
                crucial next giant step into deep and mysterious space
              </p>
              <p className={cn(styles.subtext)}>
                By Justine Bravers on July 15, 2031
              </p>
            </Nav.Link>
          </Col>
        </Row>
        <Row className={cn('g-0', styles.mainHeader)}>
          <Col md="4" className="offset-md-2">
            <Nav.Link
              className={styles.article_link}
              href="scientific-articles/13/Game-Changing-Artificial-Gestation-Technology-Passes-Final-Hurdles"
            >
              <img src={sa7} width="100%" alt="article cover" />
              <h5>
                Game Changing Artificial Gestation Technology Passes Final
                Hurdles
              </h5>
              <p>
                Artificial insemination techniques produces disease-free and
                healthy genetic eggs
              </p>
              <p className={cn(styles.subtext)}>
                By Ambers Erikson on April 9, 2034
              </p>
            </Nav.Link>
          </Col>
          <Col md="4">
            <Nav.Link
              className={styles.article_link}
              href="scientific-articles/6/GAM Announces the Creation of Automated Distributed Visionary Information System"
            >
              <img src={sa8} width="100%" alt="article cover" />
              <h5>
                GAM announces the creation of Automated Distributed Visionary
                Information System
              </h5>
              <p>
                University scientists and researchers have developed an
                artificial automated sensory nerve system
              </p>
              <p className={cn(styles.subtext)}>
                By Francis Ackowr on February 25, 2035
              </p>
            </Nav.Link>
          </Col>
        </Row>
        <Row className={cn('g-0', styles.mainHeader)}>
          <Col md="4" className="offset-md-2">
            <Nav.Link
              className={styles.article_link}
              href="scientific-articles/7/ADVIS-Selects-Final-Crew-for-Mission-to-Galeria"
            >
              <img src={sa9} width="100%" alt="article cover" />
              <h5>ADVIS Selects Final Crew for Mission to Galeria</h5>
              <p>
                The NASA-OEM Salvation project will be sending six crew members
              </p>
              <p className={cn(styles.subtext)}>
                By Martina Salvator on December 15, 2038
              </p>
            </Nav.Link>
          </Col>
          <Col md="4">
            <Nav.Link
              className={styles.article_link}
              href="scientific-articles/10/Successful-Launch-of-the-Interstellar-Lightonium-Rocket-to-Galeria"
            >
              <img src={sa10} width="100%" alt="article cover" />
              <h5>
                Successful Launch of the Interstellar Lightonium Rocket to
                Galeria
              </h5>
              <p>
                The Interstellar Lightonium Rocket launched from Florida
                carrying six members of A.D.V.I.S.
              </p>
              <p className={cn(styles.subtext)}>
                By Sherine Pancer on December 25, 2040
              </p>
            </Nav.Link>
          </Col>
        </Row>
        <Row className={cn('g-0', styles.mainHeader)}>
          <Col md="4" className="offset-md-2">
            <Nav.Link
              className={styles.article_link}
              href="scientific-articles/11/ADVIS-Lands-on-Galeria-after-39-Year-Journey-Across-Universe"
            >
              <img src={sa11} width="100%" alt="article cover" />
              <h5>
                ADVIS Lands on Galeria after 39 Year Journey Across Universe
              </h5>
              <p>
                The rocket successfully landed on the surface of Galeria,
                travelling the 369 trillion kilometres distance from planet
                Earth.
              </p>
              <p className={cn(styles.subtext)}>
                By Jackson Anders on November 30, 2080
              </p>
            </Nav.Link>
          </Col>
        </Row>
      </Container>

      <FooterSmall />
    </>
  );
};

export default ScientificArticles;
