import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import * as cn from "classnames";

import leftArrow from "../../components/images/left-arrow.png";
import rightArrow from "../../components/images/right-arrow.png";
// import "./Carousel.css";
import * as styles from "./Carousel.module.scss";


export const CarouselItem = ({ children, width }) => {
  return (
    <div className={styles['my-carousel-item']} style={{ width: width }}>
      {children}
    </div>
  );
};

const Carousel = ({ children, className }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 3000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });
  const forward = () => {
    setActiveIndex((activeIndex + 1) % children.length);
  };

  const reverse = () => {
    setActiveIndex((activeIndex + 1) % children.length);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  });

  return (
    <div
      className={
        "d-flex justify-content-center align-items-center mt-2 mb-0 pb-0"
      }
    >
      <a role="button" onClick={reverse} className={cn(styles.arrow, styles.align_right)}>
        <img
          src={leftArrow}
          width="50%"
          alt="Left Arrow"
          style={{ zIndex: 20 }}
        />
      </a>
      <div
        {...handlers}
        className={cn(styles["my-carousel"], className)}
        onMouseEnter={() => setPaused(true)}
        onMouseLeave={() => setPaused(false)}
      >
        <div
          className={styles.inner}
          style={{ transform: `translateX(-${activeIndex * 100}%)` }}
        >
          {React.Children.map(children, (child, index) => {
            return React.cloneElement(child, { width: "100%" });
          })}
        </div>
      </div>
      <a role="button" onClick={forward} className={styles.arrow}>
        <img
          src={rightArrow}
          width="50%"
          alt="Right Arrow"
          style={{ zIndex: 20 }}
        />
      </a>
    </div>
  );
};

export default Carousel;
