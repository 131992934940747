import * as cn from "classnames";
import * as styles from "./Articles.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Navigation from "../../components/navigation/Navigation";

//Images
import sa8 from "../../components/images/sa-8.png";
import sa81 from "../../components/images/sa-8-1.png";
import scientificArticlesBg from "../../components/images/scientific-articles-bg.svg";

//Components
import FooterSmall from "../../components/footer/FooterSmall";
import { ReadThisNext } from "./ReadThisNext";

const Article6 = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          GAM announces the creation of Automated Distributed Visionary
          Information System
        </title>

        <meta
          name="description"
          content="University scientists and researchers have developed an artificial
          automated sensory nerve system"
        />
      </Helmet>
      <Navigation />
      <Container
        fluid
        className={cn(styles.content, "p-0")}
        style={{
          backgroundImage: `url(${scientificArticlesBg})`,
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <Row className={cn("g-0", styles.mainHeader)}>
          <Col xs="8" md="6" className="offset-md-2 offset-2">
            <h6 className="mb-5">
              GAM announces the creation of Automated Distributed Visionary
              Information System
            </h6>
            <h4>
              University scientists and researchers have developed an artificial
              automated sensory nerve system
            </h4>
            <p className={cn(styles.sidebarArticle)}>
              By Francis Ackowr on February 25, 2035
            </p>
          </Col>
          <Col md="2">
            <ReadThisNext offset={3}></ReadThisNext>
          </Col>
        </Row>
        <Row className="g-0">
          <Col md="8" className="offset-md-2">
            <img src={sa8} width="100%" alt="article cover" />
            <p className={cn(styles.sidebarArticle)}>
              Figure 1: A.D.V.I.S. technology system - model 1.
            </p>
            <p>
              On February 25, 2035, GAM’s CEOs presented A.D.V.I.S. (Automated
              Distributed Visionary Information System) technology in front of
              the world. which is nothing but an artificial intelligent cloud
              server in the form of an augmented reality tech-tool, which has
              the consciousness of famous scientists, researchers, geniuses,
              artists, musicians, surgeons, famous philosophy teachers, expert
              engineers, famous chefs, and members of the OEM (outlive earth
              movement), including many other noble personalities.{" "}
            </p>

            <p>
              The ADVIS system technology has a collective consciousness and,
              depending upon the situation or individual’s criteria or problem,
              the system activates the consciousness of that person from the
              collective consciousness who can fix that individual’s problem or
              who falls under the category of that particular criteria.{" "}
            </p>

            <p>
              The cloud server, which is the core part of the ADVIS technology
              system, is embedded with the WORLDONICA ( Wikipedia that contains
              all the research, technology, data systems, medical science
              knowledge, fictional textbooks, OEM mission information, Galeria
              mission project information, Earth’s dying catastrophic events,
              failures, and construction that lead humanity towards Doomsday –
              data, political information, history of the earth planet, and
              everything that has ever happened on earth), interconnection
              matrix technology, artificial posterior parietal cortex lobe
              system, an NPL system (Natural and world’s language processing
              unit) for speech or voice, artificial bionic neurons, the
              artificial live central nervous system, Amazon’s voice-based
              AI-powered assistance technology named Alexa, Dr. Sandra Dono’s
              conscious transfer technology, and the system’s database, and the
              artificial automated sensory nerve system for reflexive action and
              flexibility of the ADVIS technology system.{" "}
            </p>

            <p>
              In the year 2030, Amazon, Microsoft, and Google, leading companies
              in the world after the announcement of the Earth’s dying
              prediction, came together to form a GAM organization. The
              objectives of the GAM were to create an Ark to save humanity or
              humankind. The GAM began with an ark-salvation project. They
              started many interstellar projects. And ADVIS was one of their
              main or core projects, on which they began work in 2030.The GAM
              desired to create a technology capable of consciously creating a
              perfect solution to every problem ever faced by humans and
              assisting future civilizations in living a perfect and secure life
              free of apocalyptic events. A technology on which humans can
              depend. The goal was to create a technology that is more logical,
              faster, and smarter than humans, so that it would prevent the
              future civilization from doing disastrous things that would lead
              to the destruction of the planet. Below are some of the important
              consecutive researches of famous scientists that helped in the
              creation of the ADVIS technology system.
            </p>
            <img src={sa81} width="100%" alt="article cover" />

            <p>
              In the year 2022, Neo Jarvis Catherine introduced the world to an
              interconnection matrix technology that helps individuals
              interconnect with an automated intelligence software server
              through a three-dimensional graphic barcode. Individuals who are
              linked can transfer their memory images to the server using an
              encoding process technique known as the functional magnetic
              resonance imaging sensory transportation Al system. Neo Jarvis
              also created an artificial posterior parietal cortex lobe system,
              which is installed on the automated intelligence software server
              and helps the server to understand, code-decode, and store the
              memory in arrays or blobs form.{" "}
            </p>

            <p>
              In the year 2023, Dr. Timothy Sandurize Mark a colleague of Neo
              Jarvis, developed artificial bionic neurons that can be implanted
              in paralyzed patients to circumvent nerve damage, machines, or
              robots to make them self-aware. He used ultra-low-power microchips
              approximately a few millimetres wide to create a perfect base for
              a bionic neurons device. The chips are used for intercepting
              signals that would pass between the artificial bionic neurons and
              the core system (machines, robots, server database) to which they
              are connected.
            </p>
            <p>
              Later, Dr. Sandra Dono began her research in interconnection
              matrix technology and, using Neo Jarvis’s research and technology
              of memory transfer, she developed a technology that can help
              individuals transfer their consciousness into a machine or a
              server.
            </p>

            <p>
              Stanford, Oxford, and Seoul National University scientists and
              researchers have developed an artificial automated sensory nerve
              system that can activate and deactivate the twitch reflexes in a
              human body or an AI robot. Plus, in a recent experiment, it was
              also found that the artificial automated sensory nerve system can
              also help a system, server, humans, AI robots, and machines to
              identify a potential danger, heat temperature, written information
              or data, and many possible physical threats.{" "}
            </p>

            <p>
              On February 26th, 2035, NASA placed an order to buy the ADVIS
              technology system for 900 billion dollars from GAM for their
              salvation projects. The GAM organization accepted the order and
              sent NASA one of the ADVIS technology systems from their
              organization.
            </p>
          </Col>
        </Row>
      </Container>

      <FooterSmall />
    </>
  );
};

export default Article6;
