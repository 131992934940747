import * as cn from "classnames";
import * as styles from "./OriginStory.module.scss";
import { Container, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navigation from "../../components/navigation/Navigation";

//SVGS
import trappist1System from "../../components/images/trappist-1-system.png";
import planetBuildingsFirstFrame from "../../components/images/planet_buildings_Firstframe.png";
import drSandraDonoSemiRound from "../../components/images/dr-sandra-dono-semi-round.png";
import launchedShip from "../../components/images/launched-ship.png";
import shipArrival from "../../components/images/ship-arrival.png";

//Components
import Footer from "../../components/footer/Footer";
import { VideoContainer } from "../../components/VideoContainer";
const OriginStory = () => {
  return (
    <>
      <Navigation />
      <VideoContainer
        mp4_link={`${process.env.PUBLIC_URL}/planet_buildings.mp4`}
        poster={`${planetBuildingsFirstFrame}`}
      >
        <h1 className="text-center">ORIGIN STORY</h1>
        <div className="d-flex justify-content-center mt-5">
          <Row className="g-0">
            <Col md="6" className="offset-md-3">
              <p className="text-center">
                The discovery of Trappist-1e was revealed on February 22nd 2017.
                The announcement was met with an initial burst of excitement by
                the general public, which quickly dwindled. With a distance of
                40 light-years away, the chances of exploration in the near
                future were slim to none.
              </p>
            </Col>
          </Row>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center mt-5">
          <img src={trappist1System} alt="Trappist-1 System" width="60%" />
          <p className="text-white text-center">Credit: NASA-JPL/Caltech</p>
        </div>
      </VideoContainer>

      {/* <Container
        fluid
        className="p-0"
        style={{
          backgroundImage: `url(${headerOriginStoryBg})`,
          backgroundPosition: "left center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          className={cn(
            "d-flex flex-column justify-content-center align-items-center pt-5",
            styles.headerSection,
            styles.headerStyle
          )}
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <h6 className="text-center">ORIGIN STORY</h6>
          <div className="d-flex justify-content-center mt-5">
            <Row className="g-0">
              <Col md="6" className="offset-md-3">
                <p className="text-center">
                  The discovery of Trappist-1e was revealed on February 22nd
                  2017. The announcement was met with an initial burst of
                  excitement by the general public, which quickly dwindled. With
                  a distance of 40 light-years away, the chances of exploration
                  in the near future were slim to none.
                </p>
              </Col>
            </Row>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <img src={trappist1System} alt="Trappist-1 System" width="60%" />
            <p className="text-white text-center">Credit: NASA-JPL/Caltech</p>
          </div>
        </div>
      </Container> */}
      <Container
        fluid
        className="p-0"
        style={{
          backgroundColor: "#2C9CFF",
        }}
      >
        <Row className="g-0">
          <Col
            md="6"
            className={cn(
              "offset-md-3 d-flex flex-column align-items-center justify-content-center",
              styles.textSection
            )}
          >
            <img
              src={drSandraDonoSemiRound}
              alt="Dr Sandra Dono"
              width="30%"
              className="mb-4"
            />

            <p className="text-white text-center">
              Dr. Sandra Dono began her research in 2026. While a grad student
              at MIT, she gained entrance into the elite Marsboreal Greenhouse
              project, which after starting in 2019, had grown to become the
              preeminent post-grad program for anyone interested in contributing
              to the Outlive Earth Movement (OEM).
            </p>

            <p className="text-white text-center">
              The OEM dramatically increased in popularity in 2028 with the
              election of President Eliza Sonders-Rolins. Despite President
              ESR’s best efforts, the “war” against climate change had taken a
              dark turn in 2030, leading to unfathomable planetary changes.
              Those who associated themselves with the OEM were dedicated to
              ensuring that even if the human race itself perished, the
              collective learning and knowledge that had been created over
              millennia wouldn’t be lost.
            </p>
            <img
              src={launchedShip}
              alt="Launched ship"
              width="100%"
              className="mb-4"
            />
            <div>
              <p className="text-white text-left">
                Dr. Dono had initially focused on understanding collective
                conscious transfer. When breakthroughs in organic material
                re-generation became commonplace, she realized that the
                applications of her dissertation research could be re-applied to
                enable extreme generative growth, effectively combining
                conscious mind transfer with vestigial embryonic development.
              </p>
              <p className="text-white text-left">
                As the Earth deteriorated, efforts accelerated within the OEM to
                replicate the human soul, the undefinable characteristic of what
                separates us from other mammals. Dr. Dono spearheaded this
                effort, working tirelessly to absorb all of what it meant to be
                human and process it through the best collaborative thinking in
                generations in order to create a binary representation of
                humankind.
              </p>
              <p className="text-white text-left">
                By 2040, the technology to launch an interstellar lightonium
                rocket had been established, and the OEM decided to send 1,000
                pre-embryonic self-opening undefined live sentients (S.O.U.L.S.)
                via a SpaceX payload to Trappist-1e, which was renamed Galeria
                in 2031.
              </p>
              <p className="text-white text-left">
                After landing on Galeria, the SOULS would spend 40 years
                developing in their capsules from Dr. Dono’s re-search,
                individualistically growing into their own sentient, human-like
                organic beings. This genesis group would provide the
                foundational layer to re-creating the most idealistic aspects of
                what humanity had established prior to its destruction.
              </p>
            </div>
            <div>
              <img
                src={shipArrival}
                alt="Ship arriving on Planet"
                width="100%"
                className="mb-4"
              />
              <p className="text-white text-left">
                The SOULS sent to Galeria, while organically created beings,
                needed to be designed and adapted to survive the more extreme
                conditions compared to Earth. The project’s scientists spent
                five years understanding the differences in Galeria’s
                atmosphere, topography, and composition to create genetic
                formulas that would produce more extreme physical traits. The
                traits would help the SOULS navigate the more inhospitable
                oceans, mountains, and deserts of Galeria.
              </p>
              <p className="text-white text-left">
                Each SOUL was also injected with the best of humanity, from
                music to art to athletics and more. However, as individuals, the
                genesis SOULS of Galeria could only grow so far. To flourish,
                they needed to find their MATES (matching algorithm to ensure
                survivability) and begin to populate Galeria. Each match would
                be facilitated by Dr. Dono’s research of what type of pairings
                would ensure long-term physical and relationship survivability
                to build and stabilize the new society for future generations.
              </p>
              <p className="text-white text-left">
                A perfect, predestined by the universe match would unlock,
                accelerate, and establish the best of what it meant to be human
                in the new alien yet Earth-like inhabitance of Galeria.
              </p>
              <p className="text-white text-left">
                The OEM was determined to set the SOULS up for success and avoid
                repeating the disastrous irrational decision making that had
                befallen humans and led to the earth’s destruction.
              </p>
              <p className="text-white text-left">
                They recruited some of the last remaining scientists, thinkers,
                and creatives on Earth to undertake Dr. Dono’s conscious
                transfer. This allowed their knowledge to be preserved via a
                custom automated distributed vision-ary information system
                (A.D.V.I.S.), which could be queried on-demand by the SOULS as
                they sought their MATES and created the first generation of
                Galerians.
              </p>
              <p className="text-white text-left">
                Six of these ADVIS members decided to make the trip to Galeria
                to help the SOULS establish themselves, a courageous and final
                last act.
              </p>
              <p className="text-white text-left">
                SOULMATES begins as ADVIS lands on Galeria in 2080…
              </p>
              <div
                className={cn(
                  "d-flex align-items-center justify-content-center",
                  styles.floating_border_button
                )}
              >
                <Link to="/galeria">
                  <Button variant="secondary" className={styles.floating_btn}>
                    Explore Galeria
                  </Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default OriginStory;
