import * as cn from "classnames";
import * as styles from "./Articles.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Navigation from "../../components/navigation/Navigation";

//Images
import sa12 from "../../components/images/sa-1-2.png";
import sa123 from "../../components/images/sa-1-2-3.png";
import scientificArticlesBg from "../../components/images/scientific-articles-bg.svg";

//Components
import FooterSmall from "../../components/footer/FooterSmall";
import { ReadThisNext } from "./ReadThisNext";

const Article9 = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Winner of Competition for the New Name of TRAPPIST-1E Named
        </title>

        <meta
          name="description"
          content=" The contest called on the youngest generations to take the crucial
          next giant step into deep and mysterious space"
        />
      </Helmet>
      <Navigation />
      <Container
        fluid
        className={cn(styles.content, "p-0")}
        style={{
          backgroundImage: `url(${scientificArticlesBg})`,
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <Row className={cn("g-0", styles.mainHeader)}>
          <Col xs="8" md="6" className="offset-md-2 offset-2">
            <h6 className="mb-5">
              Winner of Competition for the New Name of TRAPPIST-1E Named
            </h6>
            <h4>
              The contest called on the youngest generations to take the crucial
              next giant step into deep and mysterious space
            </h4>
            <p className={cn(styles.sidebarArticle)}>
              By Justine Bravers on July 15, 2031
            </p>
          </Col>
          <Col md="2">
            <ReadThisNext offset={0}></ReadThisNext>
          </Col>
        </Row>
        <Row className="g-0">
          <Col md="8" className="offset-md-2">
            <img src={sa12} width="100%" alt="article cover" />
            <p className={cn(styles.sidebarArticle)}>
              Credit: NASA-JPL/Caltech
            </p>
            <p>
              NASA’s SPACE STEM contest and challenges team, along with APOLLO
              50 NEXT GIANT LEAP, organized a youth competition for middle
              school and high school students that launched on November 5th,
              2030. The contest is calling on the younger generation to take the
              crucial next giant steps into deep and mysterious space. The
              SciArtReStem quoted that “If you’re between 10 and 19 years old
              and can create or design advanced technical machines and research
              about the mysterious dark planets of the Trappist system, helping
              scientists to shape ideas and civilization for the future of human
              space exploration, then you should take part in this contest.
            </p>

            <p>
              ”Lead researchers must register by the end of this year—December
              31, 2030. Winning theories, research, and designs will be selected
              by an international panel of artists, researchers, scientists,
              teachers, and astronauts, including Dr. Sandra Dono. The
              successful candidates will be awarded a total of 3.9 billion
              dollars. Team submission and participation are also welcomed.”
            </p>

            <p>
              Many scientists, researchers, astronauts, psychologists,
              geologists, and space specialists around the world took part in
              this competition. Many were rewarded too for their research,
              designs and creations that were built to protect astronauts from
              deep space radiation. But only Galeria Delgado, a 13-year-old girl
              from Portuguese, who also took part in the Space Stem 2030
              competition, wins 3.9 billion dollars of reward money for her
              research on the exoplanet Trappist-1e.{" "}
            </p>

            <img src={sa123} width="100%" alt="article cover" />
            <p>
              In her competitive research, Galeria Delgado revealed that the
              Trappist-1e planet is in a transition state; it is much closer to
              the cool dwarf star, and due to this reason, the planet is
              undergoing several changes that were not possible under the
              fundamental Kepler’s laws of planetary motion.{" "}
            </p>

            <p>
              The planet should be in a tidally locked state because of the
              large gravitational pull from the dwarf star, but this isn’t the
              case. The Trappist-1e looks like it’s tidally locked, but when she
              observed the data revealed from the James Webb telescope, it was
              opposing that fact.{" "}
            </p>

            <p>
              This means two things, as stated in her research. Firstly, the
              fundamental physics laws are not unique. The laws that govern our
              solar system and planet earth don’t apply to other galaxies or
              systems. Secondly, spacetime varies distinctively in the Trappist
              system. She also revealed that the Trappist-1e possesses several
              unusual vegetation types, including flora and fauna, green
              valleys, active volcanoes, and liquid water.
            </p>

            <p>
              It was later found that Galeria Delgado is a cancer patient who
              only has five years in front of her. She went to Musk’s Synthesis
              School and was very passionate about physics and astrophysics, and
              has written many astrophysics theories that can help scientists in
              space exploration. On July 15th, 2031, Nasa’s and SpaceX's teams
              decided to rename the planet Trappist-1e to Galeria in her honour
              and for her extreme contribution to research work in Trappist-1e.
            </p>
          </Col>
        </Row>
      </Container>

      <FooterSmall />
    </>
  );
};

export default Article9;
