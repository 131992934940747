import * as cn from "classnames";
import * as styles from "./VideoContainer.module.scss";
import { Container, Button, Row, Col } from "react-bootstrap";
import homeDroneshot from "./images/Droneshot_Static_firstframe.png";

import leftArrow from "./images/left-arrow.png";
import rightArrow from "./images/right-arrow.png";

import soulTurquoise from "./images/soul-turquoise.png";

export const VideoContainer = (props) => {
  const { poster, mp4_link, children } = props;
  return (
    <Container
      fluid
      className={cn("p-0 position-relative", styles.video_container)}
    >
      <div
        className={cn(
          "d-flex flex-column pt-0 g-0 position-absolute",
          styles.video_overlay
        )}
        style={{
          width: "100%",
          bottom: "0px",
        }}
      >
        {children}
      </div>
      
      <div>
        <video
          autoPlay
          loop
          muted
          controls=""
          preload="none"
          className={cn("position-absolute", styles.video_element)}
          style={{
            // objectFit: "cover",
            // width: "100%",
          }}
          poster={poster}
        >
          <source src={mp4_link} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </Container>
  );
};
