import * as cn from "classnames";
import * as styles from "./Articles.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Navigation from "../../components/navigation/Navigation";

//Images
import sa4 from "../../components/images/sa-4.png";
import sa41 from "../../components/images/sa-4-1.png";
import scientificArticlesBg from "../../components/images/scientific-articles-bg.svg";

//Components
import FooterSmall from "../../components/footer/FooterSmall";
import { ReadThisNext } from "./ReadThisNext";

const Article12 = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          OEM Embroiled In Latest Controversy After Mysterious Murder
        </title>

        <meta
          name="description"
          content="OEM under the inspection over disappearance of Greenpeace activist"
        />
      </Helmet>
      <Navigation />
      <Container
        fluid
        className={cn(styles.content, "p-0")}
        style={{
          backgroundImage: `url(${scientificArticlesBg})`,
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <Row className={cn("g-0", styles.mainHeader)}>
          <Col xs="8" md="6" className="offset-md-2 offset-2">
            <h6 className="mb-5">
              OEM Embroiled In Latest Controversy After Mysterious Murder
            </h6>
            <h4>
              OEM under the inspection over disappearance of Greenpeace activist
            </h4>
            <p className={cn(styles.sidebarArticle)}>
              By Barbara Canvers on August 1, 2028
            </p>
          </Col>
          <Col md="2">
            <ReadThisNext offset={0}></ReadThisNext>
          </Col>
        </Row>
        <Row className="g-0">
          <Col md="8" className="offset-md-2">
            <img src={sa4} width="100%" alt="article cover" />
            <p className={cn(styles.sidebarArticle)}>
              Greenpeace continues to speak out against climate destruction
            </p>
            <p>
              Amanda Keen is a journalist and investigative reporter who is
              famous for revealing the dark secrets of government parties,
              well-equipped companies, organizations, or people. She has been
              extensively involved with public broadcasting, producing
              documentaries, investigatory research, and news journal programs.{" "}
            </p>

            <p>
              She is always searching for the truth to do justice to the people
              by educating them about the government’s or organization’s
              terrible actions. She began her investigation after the
              assassination of Liam Tanz, his very dear friend, who was a
              brilliant scientist, MIT student, technology genius, and
              Greenpeace activist.{" "}
            </p>

            <p>
              He was murdered by someone cold-blooded in his own house, at his
              work table. Greenpeace activists from all over the world have
              started a battle against the government and legal authorities.
              They gathered in front of Liam’s house, opposing the FBI
              investigation team, quoting that “The government won’t be able to
              escape from this; we will find why they did this.”{" "}
            </p>

            <p>
              Now the question arises why Greenpeace activists were blaming the
              government for the murder of Liam Tanz. Amanda Keen revealed in
              her live report on July 17th, 2028, that Liam Tanz was working
              with SAVE EARTH, Greenpeace’s 2030 project, to save the earth from
              climatic change disasters. He developed several power home
              technologies where people can use renewable sources of energy to
              power their houses. His technology was appreciated and rewarded by
              NASA.{" "}
            </p>
            <img src={sa41} width="100%" alt="article cover" />
            <p>
              They recently offered him an offer letter for the OEM project, but
              he declined. He doesn’t want to work with a research organization
              that generates 300 million tonnes per year of toxic waste in the
              name of science and research.{" "}
            </p>

            <p>
              She quoted, “Why was a brilliant scientist, who usually remains
              busy at work, who doesn’t get involved in fights with strangers or
              anyone, murdered in cold bold?” After that broadcast, many
              Greenpeace activists got involved in various stunts to disrupt
              NASA’s authorities.{" "}
            </p>

            <p>
              The huge crowd of activists in Washington, D.C. destroyed the
              Salvation-X project of NASA, which was funded by the SpaceX
              organization. Many Greenpeace activists have climbed inside the
              OEM centre column administration office to protest. They destroyed
              the office and machines that generated toxic waste.{" "}
            </p>

            <p>
              After this controversy, the obvious question arises: was OEM
              behind the murder of Liam Tanz? What is it that they are hiding?
              Is it something about climate change, the Save Earth Project, or
              the Outlive Earth Movement (OEM)? And why has NASA initiated many
              salvation projects? Did Liam find something apocalyptic that might
              destroy planet Earth?
            </p>

            <p>
              The government authorities, after this drastic act of activism,
              ordered to put Greenpeace activists behind bars. Amanda Keen
              started her own series of investigations through her reliable
              sources.{" "}
            </p>

            <p>
              She revealed that Liam Tanz was speculating on the OEM’s data and
              their research work. She says in a statement, "It looks like Liam
              either found something related to the OEM project or something
              cascading about the SAVE EARTH project, which NASA’s authorities
              and OEM wouldn’t want to share with him or with the world."
            </p>
          </Col>
        </Row>
      </Container>

      <FooterSmall />
    </>
  );
};

export default Article12;
