import * as cn from "classnames";
import { Navbar, Container, Nav, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Twitter, Discord } from "react-bootstrap-icons";
const styles = require("./Navigation.module.scss");

{
  /* <Link to="/page/3" className="nav-link text-nowrap">
SOULS
</Link> */
}
const Navigation = () => {
  return (
    <Navbar bg="white" expand="lg" variant="light">
      <Container>
        <Link className="navbar-brand" to="/">
          <img
            src="/logo192.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={cn(styles.spaced)}>
            <Link to="/origin-story" className="nav-link text-nowrap">
              Origin Story
            </Link>
            <Link to="/galeria" className="nav-link text-nowrap">
              Galeria
            </Link>
            <Link to="/souls" className="nav-link text-nowrap">
              SOULS
            </Link>
            <Link to="/expedition-team" className="nav-link text-nowrap">
              Expedition Team
            </Link>
            <Link
              to={{
                pathname: "/",
                hash: "#mission-control",
              }}
              state={{
                missionControl: true,
              }}
              className="nav-link text-nowrap"
            >
              Mission Control
            </Link>
            <Link to="/scientific-articles" className="nav-link text-nowrap">
              Scientific Articles
            </Link>
            <Link
              to={{
                pathname: "/",
                hash: "#roadmap",
              }}
              state={{
                roadmap: true,
              }}
              className="nav-link text-nowrap"
            >
              Roadmap
            </Link>
            <a
              href="/SOULMATES_01_Whitepaper_April_2022.pdf"
              className="nav-link text-nowrap"
              target="_blank"
            >
              Whitepaper
            </a>
            <Button
              className={cn(styles["nav-h32"], "me-2 btn-sm nav-button-height")}
              variant="primary"
              href="https://twitter.com/soulmatesmissn"
            >
              <Twitter />
            </Button>
            <Button
              className={cn(styles["nav-h32"], "me-2 btn-sm nav-button-height")}
              variant="primary"
              href="https://twitter.com/soulmatesmissn"
            >
              <Discord />
            </Button>
          </Nav>
          <Form inline="true"></Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

{
  /* <Button
className={cn(styles['nav-h32'], 'btn-sm nav-button-height')}
variant="primary"
href="/page"
>
Connect Wallet
</Button> */
}

export default Navigation;
