import * as cn from 'classnames';
import * as styles from './Souls.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Navigation from '../../components/navigation/Navigation';

//Image assets
import soulGreen from '../../components/images/soul-green.png';
import sgsStep from '../../components/images/sgs-step.png';
import * as attributes from './attributeConstants';
//Components
import Footer from '../../components/footer/Footer';
import SliderCarousel from '../../components/carousel/SliderCarousel';
import { Pod } from '../../components/pod/Pod';

const SoulGenerationStep = (props) => {
  const {
    icon,
    iconAlt,
    iconWidth,
    hrSize,
    stepTitle,
    stepDescription,
    hrDisabled,
  } = props;

  return (
    <div className={cn('d-flex justify-content-space-between')}>
      <div className="d-flex flex-column  align-items-center">
        <img src={icon} alt={iconAlt} width={iconWidth} />
        {hrDisabled ? null : (
          <hr width="1" size={hrSize} className="mx-4 my-0" />
        )}
      </div>
      <div className={cn('mt-3 ms-5', styles.roadmapFlex)}>
        <h6>{stepTitle}</h6>

        <p>{stepDescription}</p>
      </div>
    </div>
  );
};

const Attributes = (props) => {
  const { color, attributes, headers } = props;
  return (
    <>
      <Row
        className={cn(
          styles.mode,
          color === 'dark' ? styles.dark : styles.light
        )}
      >
        {headers.map((header) => (
          <Col>
            <h6>{header}</h6>
          </Col>
        ))}
      </Row>
      {attributes.map((attribute) => (
        <Row
          className={cn(
            styles.mode,
            color === 'dark' ? styles.dark : styles.light
          )}
        >
          <Col>
            <p>{attribute.name}</p>
          </Col>
          <Col>
            <p>{attribute.value}</p>
          </Col>
        </Row>
      ))}
    </>
  );
};

const Souls = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>S.O.U.L.S. - Soulmates</title>
        <link rel="canonical" href="http://soulmatesmission.com/souls" />
        <meta name="description" content="S.O.U.L.S. - SOULMATES" />
      </Helmet>
      <Navigation />
      <Container
        fluid
        className={cn(styles.content, 'p-0')}
        style={{
          backgroundImage: `url(${'/souls/souls_background.png'})`,
          backgroundPosition: 'left bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <h6 className="text-center">S.O.U.L.S</h6>
        <Row className="g-0">
          <Col
            xs="10"
            md="6"
            className={cn('offset-1 offset-md-3 position-relative', styles.textSection)}
          >
            <p className=" text-center">
              The first generation will be 2,080 SOULS, with the possibility of
              1,040 MATES (Origin Collection).
            </p>

            <p className=" text-center">
              The stewards of these SOULS will need to find their predestined
              match in order to unlock the ability to create the next generation
              together in a unique cooperative social experiment.
            </p>

            <p className=" text-center">
              When minting a new POD/SOUL, users will be given access to a
              personality quiz. Entries into this quiz will inform the
              non-physical personality attributes of each newly minted SOUL. The
              personality quiz will be a re-quirement to mint each SOUL.
            </p>

            <p className="text-center">
              Each mint would provide the minter with 1 SOUL POD, and the
              matching mechanism would facilitate the search until each SOUL has
              a match.
            </p>
          </Col>
        </Row>
        <Row className="g-0">
          <Col
            xs="10"
            md="6"
            className={cn('offset-1 offset-md-3 position-relative', styles.textSection)}
          >
            <div className="d-flex flex-row justify-content-center align-items-center">
              <img
                src={soulGreen}
                alt="Soul Green"
                width="50%"
                className="mb-4"
              />
            </div>
            <p className=" text-center">
              Each SOUL will need to find their predestined match in order to
              unlock the ability to create the next generation together in a
              unique cooperative social experiment.
            </p>

            <p className=" text-center">
              The Origin generation will mature through two phases, POD to
              ADULT. Each ADULT pair will be able to pro-create a certain number
              of times, based on the cooperation between the two SOULs’ stewards
              and the rarity of the match determined by their combined
              characteristic score.
            </p>

            <p className=" text-center">
              Each SOUL has 9 physical characteristics with 5 to 20 variations
              each and 18 personality attribute genes. This results in the
              make-up consisting of 59,976,000 physical combinations, over 5
              quintillion personality combinations and the possibility of over
              300 septillion unique SOULS.
            </p>

            <p className=" text-center">
              The distribution of the PODs is random, so no one knows which will
              hatch the most unique SOULS.
            </p>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className={cn(styles.content, 'p-0')}
        style={{
          //backgroundImage: `url(${expeditionTeamBg1})`,
          backgroundPosition: 'left bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Row className="g-0">
          <Pod
            side="left"
            variant="blue"
            width="80%"
            xoffset="50px"
            top="-180px"
          />
          <Col
            xs="10"
            md="6"
            className={cn('offset-1 offset-md-3 position-relative', styles.textSection)}
          >
            <h1 className="text-center">SOUL GENERATIONS</h1>
            <Row id="roadmap" className="g-0">
              <Col
                xs="12"
                md="12"
                className={cn(
                  'offset-0 offset-md-0',
                  styles.textSection,
                  styles.roadmap
                )}
              >
                <SoulGenerationStep
                  icon={sgsStep}
                  iconAlt="step2"
                  iconWidth="65%"
                  hrSize="64"
                  stepTitle="PODS"
                  stepDescription="Mint your PODS which will have different levels of rarity
                  based on how the characteristics are defined"
                />
                <SoulGenerationStep
                  icon={sgsStep}
                  iconAlt="step2"
                  iconWidth="65%"
                  hrSize="38"
                  stepTitle="SOULS"
                  stepDescription="Watch your PODs hatch into their unique SOULS"
                />
                <SoulGenerationStep
                  icon={sgsStep}
                  iconAlt="step2"
                  iconWidth="65%"
                  hrSize="70"
                  stepTitle="Find your Soulmate"
                  stepDescription="The specific matching mechanism will be created in which you’ll find your SOULS perfect MATE in collaboration with other SOUL holders."
                />
                <SoulGenerationStep
                  icon={sgsStep}
                  iconAlt="step2"
                  iconWidth="65%"
                  hrSize="120"
                  stepTitle="Grow your Lineage"
                  stepDescription="Work through a series of activities to improve your chances of you and your SOULS’ MATE creating successive generations. Theses activities will be created with input from the community. The greater and stronger the generations, the greater the participation in future upside and sub-game opportunities."
                />
                <SoulGenerationStep
                  icon={sgsStep}
                  iconAlt="step2"
                  iconWidth="65%"
                  hrSize="120"
                  stepTitle="Generations"
                  stepDescription="Build out an entire generation of SOULS, potentially matching with other lineages to improve characteristic scores. Characteristics could become their own sub-games or NFTs. A genetic algorithm would be created to combine characteristics and pass them down."
                />
                <SoulGenerationStep
                  icon={sgsStep}
                  iconAlt="step2"
                  iconWidth="65%"
                  hrDisabled={true}
                  stepTitle="Population Metaverse"
                  stepDescription="Transition the generations into an ongoing game and/or metaverse environment. Actual soulmates participants (stewards) could be communicating IRL"
                />
              </Col>
            </Row>
            <p className=" text-center">
              Once the initial generation of SOULS match, they will be able to
              combine to create the next generation (Expansion Collection).
            </p>

            <p className=" text-center">
              The second generation will be 8,000 SOULS, with the possibility of
              4,000 MATCHES. That generation will also mature through 2 phases,
              PODS and ADULT.
            </p>

            <p className=" text-center">
              Future generations will also be able to match, creating a
              multi-generational lineage.
            </p>
          </Col>
        </Row>
        <Row className="g-0">
          <Pod side="right" variant="salmon" top="-125px" />
          <Col
            xs="10"
            md="6"
            className={cn('offset-1 offset-md-3 position-relative', styles.textSection)}
          >
            <div className="d-flex flex-row justify-content-center align-items-center">
              <img
                src={'/souls/physical_attributes.png'}
                alt="Physical Attributes Soul"
                width="70%"
                className="mb-4"
              />
            </div>
            <h1 className="text-center">Physical Attributes</h1>
            <Attributes
              color="dark"
              headers={['Characteristic', 'Options']}
              attributes={[
                { name: 'Background', value: '5' },
                { name: 'Body', value: '21' },
                { name: 'Arms', value: '7' },
                { name: 'Legs', value: '8' },
                { name: 'Ears', value: '12' },
                { name: 'Head', value: '4' },
                { name: 'Mouth', value: '2' },
                { name: 'Eyes', value: '4' },
                { name: 'Texture', value: '17' },
              ]}
            />
            <h5 className="text-center my-3">59,976,000 total combinations</h5>
          </Col>
        </Row>

        <Row className="g-0">
          <Pod
            side="left"
            variant="yellow"
            width="80%"
            xoffset="-12px"
            top="-100px"
          />
          <h1 className="text-center">COLORS</h1>
          <Col
            xs="10"
            md="6"
            className={cn('offset-1 offset-md-3 position-relative', styles.textSection)}
          >
            <SliderCarousel
              slides={attributes.colorOptions}
              total={attributes.colorOptions.length}
              steps={1}
              width={100}
              height={270}
              visible={3}
            />
          </Col>
        </Row>
        <Row className="g-0">
          <h1 className="text-center">TEXTURES</h1>
          <Col
            xs="10"
            md="6"
            className={cn('offset-1 offset-md-3 position-relative', styles.textSection)}
          >
            <SliderCarousel
              slides={attributes.textureOptions}
              total={attributes.textureOptions.length}
              steps={1}
              width={1295}
              height={3417}
              visible={1}
              interval={4000}
              isSingle={true}
              itemClassName={styles.ears}
            />
          </Col>
        </Row>
        <Row className="g-0">
          <Pod side="right" variant="salmon" top="-300px" />
          <h1 className="text-center">EARS</h1>
          <Col
            xs="10"
            md="6"
            className={cn('offset-1 offset-md-3 position-relative', styles.textSection)}
          >
            <SliderCarousel
              slides={attributes.earOptions}
              total={attributes.earOptions.length}
              steps={1}
              width={400}
              height={420}
              visible={3}
              interval={3000}
              buttonsDisabled={true}
              isEars={true}
            />
          </Col>
        </Row>
        <Row className="g-0">
          <h1 className="text-center">ARMS</h1>
          <Col
            xs="10"
            md="6"
            className={cn('offset-1 offset-md-3 position-relative', styles.textSection)}
          >
            <SliderCarousel
              slides={attributes.armOptions}
              total={attributes.armOptions.length}
              steps={1}
              width={1327}
              height={1500}
              visible={1}
              interval={4000}
              isSingle={true}
            />
          </Col>
        </Row>
        <Row className="g-0">
          <h1 className="text-center">LEGS</h1>
          <Col
            xs="10"
            md="6"
            className={cn('offset-1 offset-md-3 position-relative', styles.textSection)}
          >
            <SliderCarousel
              slides={attributes.legOptions}
              total={attributes.legOptions.length}
              steps={1}
              width={1396}
              height={2086}
              visible={1}
              interval={6000}
              isSingle={true}
            />
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className={cn(styles.content, 'p-0')}
        style={{
          //backgroundImage: `url(${expeditionTeamBg1})`,
          backgroundColor: '#2C9CFF',
          //backgroundPosition: 'left bottom',
          //backgroundRepeat: 'no-repeat',
          //backgroundSize: 'cover',
        }}
      >
        <Row className={cn('g-0')}>
          <Pod
            side="left"
            variant="blue"
            width="80%"
            xoffset="-12px"
            top="-200px"
          />
          <Col
            xs="10"
            md="6"
            className={cn(
              'offset-md-3 position-relative',
              styles.textSectionLight
            )}
          >
            <h1 className="text-center">Personality Attributes</h1>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <img
                src={'/souls/personality_attributes.png'}
                alt="Personality Attributes Soul"
                width="80%"
                className="mb-4"
              />
            </div>

            <Attributes
              color="light"
              headers={['Characteristic', 'Options']}
              attributes={[
                { name: 'Birth Sign (1)', value: '12' },
                { name: 'Love Type (1)', value: '6' },
                { name: 'Blood Type (1)', value: '8' },
                { name: 'DNA Configuration (TBD)', value: '4' },
                { name: 'Archetype (1)', value: '12' },
                { name: 'Intelligence Quotient (1)', value: '21' },
                {
                  name: 'Intelligence Type (0 - 100 score on each)',
                  value: '8',
                },
                { name: 'Philosophical Leaning (1)', value: '10' },
                { name: 'Personality - Interactivity (% split)', value: '2' },
                { name: 'Personality - Decision Making (% split)', value: '2' },
                { name: 'Personality - Intuition (% split)', value: '2' },
                { name: 'Personality - Gather Info (% split)', value: '2' },
                { name: 'Virtues (Pick 2)', value: '20' },
                { name: 'Left Brain (Pick 2)', value: '13' },
                { name: 'Right Brain (Pick 2)', value: '13' },
                { name: 'Aptitude (Pick 2)', value: '11' },
                { name: 'STEM Style (Pick 1)', value: '9' },
                { name: 'Name (Random)', value: '2400' },
              ]}
            />
            <h5 className="text-center my-3">
              5 Quintillion total combinations
            </h5>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default Souls;
