import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { parseProviderOrSigner } from "eth-hooks/functions/providerOrSigner";
/**
 * Loads your local contracts and gives options to read values from contracts
  or write transactions into them

   ~ Features ~
  - localProvider enables reading values from contracts
  - userProvider enables writing transactions into contracts
  - Example of keeping track of "purpose" variable by loading contracts into readContracts
    and using ContractReader.js hook:
    const purpose = useContractReader(readContracts,"YourContract", "purpose")
  - Example of using setPurpose function from our contract and writing transactions by Transactor.js helper:
    tx( writeContracts.YourContract.setPurpose(newPurpose) )

  config can include:
  - chainId - to hardcode the chainId, irrespective of the providerOrSigner chainId
  - hardhatNetworkName - to hardcode the hardhat network of interest
  - customAddresses: { contractName: 0xCustomAddress } to hardcode the address for a given named contract
  - hardhatContracts: object following the hardhat deploy export format (Json with chainIds as keys, which have hardhat network names as keys, which contain arrays of contracts for each)
  - externalContracts: object with chainIds as keys, with an array of contracts for each
 * @param providerOrSigner (TEthersProviderOrSigner)
 * @param config (TContractConfig) :: configuration for loader
 * @returns (Record<string, Contract>) :: a record of contractName:contract
 */
export const useContractLoaderReload = (
  providerOrSigner,
  config = {},
  chainId
) => {
  const [contracts, setContracts] = useState({});
  const configDep = useMemo(
    () => JSON.stringify(config !== null && config !== void 0 ? config : {}),
    [config]
  );
  useEffect(() => {
    let active = true;
    const loadContracts = async () => {
      var _a, _b, _c, _d, _e;
      if (providerOrSigner && typeof providerOrSigner !== "undefined") {
        try {
          // we need to check to see if this providerOrSigner has a signer or not
          if (typeof providerOrSigner !== "undefined") {
            // we need to check to see if this providerOrSigner has a signer or not
            const { providerNetwork } = await parseProviderOrSigner(
              providerOrSigner
            );
            // find the current chainId based on this order:
            //  - chainId passed in or a fallback of provider chainId
            const currentChainId =
              (_a =
                chainId !== null && chainId !== void 0
                  ? chainId
                  : providerNetwork === null || providerNetwork === void 0
                  ? void 0
                  : providerNetwork.chainId) !== null && _a !== void 0
                ? _a
                : 0;
            // Type definition
            //  Record<string, Record<string, Contract>>
            //  { chainId: { contractName: Contract } }
            const contractList = Object.assign(
              {},
              (_b = config.deployedContracts) !== null && _b !== void 0
                ? _b
                : {}
            );
            const externalContractList = Object.assign(
              {},
              (_c = config.externalContracts) !== null && _c !== void 0
                ? _c
                : {}
            );
            let combinedContracts = {};
            // combine partitioned contracts based on all the available and chain id.
            if (
              (contractList === null || contractList === void 0
                ? void 0
                : contractList[currentChainId]) != null
            ) {
              for (const hardhatNetwork in contractList[currentChainId]) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    contractList[currentChainId],
                    hardhatNetwork
                  )
                ) {
                  if (
                    !config.hardhatNetworkName ||
                    hardhatNetwork === config.hardhatNetworkName
                  ) {
                    combinedContracts = Object.assign(
                      Object.assign({}, combinedContracts),
                      (_e =
                        (_d =
                          contractList === null || contractList === void 0
                            ? void 0
                            : contractList[currentChainId]) === null ||
                        _d === void 0
                          ? void 0
                          : _d[hardhatNetwork]) === null || _e === void 0
                        ? void 0
                        : _e.contracts
                    );
                  }
                }
              }
            }
            if (
              (externalContractList === null || externalContractList === void 0
                ? void 0
                : externalContractList[currentChainId]) != null
            ) {
              combinedContracts = Object.assign(
                Object.assign({}, combinedContracts),
                externalContractList[currentChainId].contracts
              );
            }
            const newContracts = Object.keys(combinedContracts).reduce(
              (accumulator, contractName) => {
                const address =
                  config.customAddresses &&
                  Object.keys(config.customAddresses).includes(contractName)
                    ? config.customAddresses[contractName]
                    : combinedContracts[contractName].address;
                accumulator[contractName] = new ethers.Contract(
                  address,
                  combinedContracts[contractName].abi,
                  providerOrSigner
                );
                return accumulator;
              },
              {}
            );
            if (active) setContracts(newContracts);
          }
        } catch (e) {
          console.log("ERROR LOADING CONTRACTS!!", e);
        }
      }
    };
    void loadContracts();
    return () => {
      active = false;
    };
    // disable as configDep is used for dep instead of config
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerOrSigner, configDep, chainId]);
  return contracts;
};
