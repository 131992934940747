import { useState, useEffect } from "react";
import { loadNFTContracts } from "../helpers/loadNFTContracts";

export const useContractConfig = (provider, ethNetwork) => {
  const [contractsConfig, setContractsConfig] = useState({});

  useEffect(() => {
    const loadFunc = async () => {
      const result = await loadNFTContracts(ethNetwork);
      setContractsConfig(result);
    };
    void loadFunc();
  }, [provider, ethNetwork]);
  return contractsConfig;
};
