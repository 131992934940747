import * as cn from "classnames";
import * as styles from "./Articles.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Navigation from "../../components/navigation/Navigation";

//Images
import sa11 from "../../components/images/sa-11.png";
import scientificArticlesBg from "../../components/images/scientific-articles-bg.svg";

//Components
import FooterSmall from "../../components/footer/FooterSmall";
import { ReadThisNext } from "./ReadThisNext";

const Article11 = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          ADVIS Lands on Galeria after 39 Year Journey Across Universe
        </title>

        <meta
          name="description"
          content="The rocket successfully landed on the surface of Galeria,
          travelling the 369 trillion kilometres distance from planet Earth."
        />
      </Helmet>
      <Navigation />
      <Container
        fluid
        className={cn(styles.content, "p-0")}
        style={{
          backgroundImage: `url(${scientificArticlesBg})`,
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <Row className={cn("g-0", styles.mainHeader)}>
          <Col xs="8" md="6" className="offset-md-2 offset-2">
            <h6 className="mb-5">
              ADVIS Lands on Galeria after 39 Year Journey Across Universe
            </h6>
            <h4>
              The rocket successfully landed on the surface of Galeria,
              travelling the 369 trillion kilometres distance from planet Earth.
            </h4>
            <p className={cn(styles.sidebarArticle)}>
              By Jackson Anders on November 30, 2080
            </p>
          </Col>
          <Col md="2">
            <ReadThisNext offset={0}></ReadThisNext>
          </Col>
        </Row>
        <Row className="g-0">
          <Col md="8" className="offset-md-2">
            <img src={sa11} width="100%" alt="article cover" />
            <p className={cn(styles.sidebarArticle)}>
              Illustration of the approach to Galeria
            </p>
            <p>
              On January 2nd, 2080, the Interstellar Lightonium Rocket
              (Galeria’s mission) reached the Trappist system; and on November
              24th, 2080, after about 39 years, the rocket successfully landed
              on the surface of Galeria, traveling the 369 trillion kilometres
              distance from planet Earth.
            </p>

            <p>
              When the interstellar system was evaluated, it showed some
              ambiguity and malfunctioning in the payload system and launch
              lug-fins of the rocket. The ADVIS members were awakened from their
              hibernation sleep after 39 years. They found that the interstellar
              rocket was malfunctioning and the appointed commander of the
              Galeris’s mission, Dr. Charles Obstansa and Marie Odwayo, was now
              on board.{" "}
            </p>

            <p>
              They found that the rocket fins and payload had been crashed and
              hit by the icy mountain during the vertical landing process of the
              interstellar Lightonium Rocket. The Galeria’s mission was started
              and commenced as planned. The ADVIS members initiated the
              departure process in command. After equating or shifting
              everything, including the research system, life support system,
              and half of the supplies from the rocket to the 50 fully
              functioning mini spaceships and transport systems, the
              civilization-habitat colony project was initiated by the ADVIS
              members.{" "}
            </p>

            <p>
              AI-Tars and Case started constructing secure domes, Galeris’s
              base, circumnavigation ports, and greenhouse plantation domes
              inside the rocky-icy-cave area on the Galeria’s surface. Dr.
              Alejandro Curez observed something mysterious beneath the caves
              that are on the Galeria’s surface, which might be the reason
              behind the uncertain increasing and decreasing seismic vibrations.{" "}
            </p>

            <p>
              It was some kind of portal that was radiating several vibrations
              and harmful radiation. Later, Dr. Alejandro Curez found that the
              cave was emitting thermal phantom-like radiation, which usually
              comes from a space-time wormhole.
            </p>

            <p>Atmosphere: Denoted breathable to humans</p>

            <p>
              Climate: Equilibrium temperature 249.5 K, Windy pattens, irregular
              storms
            </p>

            <p>
              Seismology: Rapid increase and decrease in Seismic activity,
              (reason: Still unknown)
            </p>

            <p>
              Geology: Rocky- icy surface; iron magnesium copper, lead, oxygen
              and silicon observed in soil composition; potential liquid water
              around valleys and caves
            </p>
          </Col>
        </Row>
      </Container>

      <FooterSmall />
    </>
  );
};

export default Article11;
