//LIBRARIES
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { ethers } from 'ethers';
import { has } from 'lodash';

//HOOKS
import { useContractConfig } from './hooks/useContractConfig';
import { useNetwork } from './hooks/useNetwork';
import { useSigner } from './hooks/useSigner';
import { useContractLoaderReload } from './hooks/useContractLoaderReload';
// import { useContractLoader } from "eth-hooks";

//STYLES
import './App.module.scss';
import './index.scss';

//PAGES
import Home from './pages/home/Home';
import OriginStory from './pages/origin-story/OriginStory';
import Souls from './pages/souls/Souls';
import Galeria from './pages/galeria/Galeria';
import ExpeditionTeam from './pages/expedition-team/ExpeditionTeam';
import BlogLayout from './pages/scientific-articles/BlogLayout';
import ScientificArticles from './pages/scientific-articles/ScientificArticles';
import Article6 from './pages/scientific-articles/Article6';
import Article7 from './pages/scientific-articles/Article7';
import Article8 from './pages/scientific-articles/Article8';
import Article9 from './pages/scientific-articles/Article9';
import Article10 from './pages/scientific-articles/Article10';
import Article11 from './pages/scientific-articles/Article11';
import Article12 from './pages/scientific-articles/Article12';
import Article13 from './pages/scientific-articles/Article13';
import Article15 from './pages/scientific-articles/Article15';
import Article16 from './pages/scientific-articles/Article16';
import Article17 from './pages/scientific-articles/Article17';

//COMPONENTS
import Navigation from './components/navigation/Navigation';
import ScrollTop from './components/ScrollTop';
//import HomepageHero from "./components/HomepageHero";
//import Footer from "./components/Footer";
//import MeetTheSouls from './components/MeetTheSouls'
//import Roadmap from './components/Roadmap'
//import Team from './components/Team'

import { contractAddressByNetwork } from './helpers/contractAddresses';
import MintSection from './components/MintSection';

const useProvider = (wndEthereum) => {
  const [provider, setProvider] = useState(null);

  const updateProvider = () => {
    let p = null;
    if (wndEthereum !== undefined) {
      p = new ethers.providers.Web3Provider(wndEthereum, 'any');
      setProvider(p);
    }
    return p;
  };

  useEffect(() => {
    const p = updateProvider();
    if (p !== null) {
      p.on('network', () => {
        console.log('got network change');
        updateProvider();
      });
    }
  }, [wndEthereum]);

  return [provider, setProvider];
};

export function AppProvider(props) {
  const [provider, setProvider] = useProvider(window.ethereum);
  return <App provider={provider} setProvider={setProvider}></App>;
}

export function App(props) {
  const { provider, setProvider } = props;
  const [network, updateNetwork] = useNetwork(provider);

  const [signer, updateSigner] = useSigner(provider);
  const [EthNetworkName, setEthNetworkName] = useState('homestead');

  let networkToShow = network !== undefined ? network.name : EthNetworkName;

  const contractConfig = useContractConfig(provider, networkToShow);

  const [chainId, setChainId] = useState();
  useEffect(() => {
    if (network !== undefined) {
      setChainId(network.chainId);
    }
  }, [network]);

  const readContracts = useContractLoaderReload(
    provider,
    contractConfig,
    chainId
  );
  const writeContracts = useContractLoaderReload(
    signer,
    contractConfig,
    chainId
  );

  // WARNING
  return (
    <div>
      <BrowserRouter>
        <ScrollTop>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/origin-story" element={<OriginStory />} />
            <Route exact path="/galeria" element={<Galeria />} />
            <Route exact path="/souls" element={<Souls />} />
            <Route exact path="/expedition-team" element={<ExpeditionTeam />} />
            {/* Nested articles routes - react router v6 */}
            <Route path="/scientific-articles" element={<BlogLayout />}>
              <Route index element={<ScientificArticles />} />
              <Route path="6/:articleSlug" element={<Article6 />} />
              <Route path="7/:articleSlug" element={<Article7 />} />
              <Route path="8/:articleSlug" element={<Article8 />} />
              <Route path="9/:articleSlug" element={<Article9 />} />
              <Route path="10/:articleSlug" element={<Article10 />} />
              <Route path="11/:articleSlug" element={<Article11 />} />
              <Route path="12/:articleSlug" element={<Article12 />} />
              <Route path="13/:articleSlug" element={<Article13 />} />
              <Route path="15/:articleSlug" element={<Article15 />} />
              <Route path="16/:articleSlug" element={<Article16 />} />
              <Route path="17/:articleSlug" element={<Article17 />} />
            </Route>
            {/**
        <Route exact path="/" element={<HomepageHero
            signer={signer}
            network={network}
            updateNetwork={updateNetwork}
            heroInfoSection={
              <MintSection
                signer={signer}
                provider={provider}
                setProvider={setProvider}
                updateSigner={updateSigner}
                network={network}
                updateNetwork={updateNetwork}
                writeContracts={writeContracts}
                readContracts={readContracts}
              ></MintSection>
            }
          >
            <MeetTheSouls></MeetTheSouls>
            <Roadmap></Roadmap>
            <Team></Team>
            <Footer></Footer>
          </HomepageHero>}>
          
        </Route>
        <Route path="/mint" element={
          <div>Mint!</div>}>
        </Route>
 */}
          </Routes>
        </ScrollTop>
      </BrowserRouter>
    </div>
  );
}
//
export default App;
