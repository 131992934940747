import * as cn from "classnames";
import * as styles from "./Footer.module.scss";
import { isNil } from "lodash";
import { Container, Row, Col } from "react-bootstrap";
import { Twitter, Discord, Globe } from "react-bootstrap-icons";
import soulYellowLight from "../../components/images/soul-yellow-light.png";
import soulBlueLight from "../../components/images/soul-blue-light.png";
import soulPinkLight from "../../components/images/soul-pink-light.png";

const Footer = ({ introText }) => {
  return (
    <Container
      fluid
      className={cn("p-0", styles.section)}
      style={{
        backgroundColor: "#FFF",
      }}
    >
      <Row className="g-0">
        <Col
          xs="10"
          md="4"
          className={cn("offset-1 offset-md-4", styles.textSection, {
            "mt-5": !isNil(introText),
          })}
        >
          {introText}
          <div className="d-flex align-items-center justify-content-center mt-5">
            <img
              src={soulBlueLight}
              alt="blue soul"
              width="150px"
              className={cn(styles.narrow_hide, "mx-5")}
            />
            <img
              src={soulPinkLight}
              alt="pink soul"
              width="150px"
              className="mx-5"
            />
            <img
              src={soulYellowLight}
              alt="yellow soul"
              width="150px"
              className={cn(styles.narrow_hide, "mx-5")}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center mt-5">
            <a href="https://soulmatesmission.com">
              <Globe color="#2C9CFF" size={35} className="mx-3" />
            </a>
            <a href="https://twitter.com/soulmatesmissn">
              <Twitter color="#2C9CFF" size={35} className="mx-3" />
            </a>
            <a>
              <Discord color="#2C9CFF" size={35} className="mx-3" />
            </a>
          </div>
        </Col>
      </Row>
      <hr />
    </Container>
  );
};

export default Footer;
