import * as cn from 'classnames';
import * as styles from './Pod.module.scss';
import { isNil } from 'lodash'

import SalmonSphere from "../../components/images/sphere-salmon.png";
import BlueSphere from "../../components/images/sphere-blue.png";
import YellowSphere from "../../components/images/sphere-yellow.png";
// import the 3 kinds of pods
// provide rotation
// provide sizing it
// smart responsive positioning

const variantLookup = {
  salmon: SalmonSphere,
  blue: BlueSphere,
  yellow: YellowSphere
}

export function Pod(props) {
  const { width, rotation, variant, side, top, xoffset, bottom, bottomattached } = props;

  // return null;


  return (<div className={cn(styles.sphere, {
    [styles.left]: side === 'left',
    [styles.right]: side === 'right',
  })} style={{
    'marginTop': top,
    'marginLeft': xoffset,
    'marginBottom': bottom,
    'bottom': bottomattached ? '0px' : null
  }}>
    <img className={cn(styles.img)} src={variantLookup[variant]} style={{
      'width': !isNil(width) ? width : '100%',
    }}/>
  </div>)
}