import { useState, useEffect } from "react";
import keccak256 from "keccak256";
import { ethers } from "ethers";
import { map, isEmpty, findIndex } from "lodash";
import { MerkleTree } from "merkletreejs";
const importPromise = import("../contracts/whitelist.json");

export const loadWhitelist = async () => {
  const res = (await importPromise).default ?? {};
  return res;
};

export const useWhitelistJson = (provider) => {
  const [whitelist, setWhitelist] = useState({});

  useEffect(() => {
    const loadFunc = async () => {
      const result = await loadWhitelist();
      // load hashes
      result.hashes = map(result.hashes, (h) => {
        return Buffer.from(h);
      });
      // console.log("result", result);
      setWhitelist(result);
    };
    void loadFunc();
  }, [provider]);
  return whitelist;
};

export const useWhitelistIndex = (whitelist, address) => {
  const [whitelistIndex, setWhitelistIndex] = useState(-1);

  useEffect(() => {
    const loadFunc = async () => {
      if (address === null || address === undefined || isEmpty(whitelist)) {
        return false;
      }
      const addrHash = keccak256(
        ethers.utils.defaultAbiCoder.encode(["address"], [address])
      );

      // find index
      const i = findIndex(whitelist.hashes, (h) => {
        const c = addrHash.compare(h);
        return c === 0;
      });

      setWhitelistIndex(i);
    };
    void loadFunc();
  }, [whitelist, address]);

  return whitelistIndex;
};

export const generateMerkleTree = (whitelist) => {
  const leafNodes = whitelist.hashes.map((addrHash, i) =>
    keccak256(
      ethers.utils.defaultAbiCoder.encode(
        ["uint", "uint", "uint", "uint"],
        [i, whitelist.amounts[i], addrHash, 1]
      )
    )
  );

  const merkleTree = new MerkleTree(leafNodes, keccak256, {
    sortPairs: true,
  });

  return merkleTree;
};
