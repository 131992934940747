import * as cn from 'classnames';
import * as styles from './Articles.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Navigation from "../../components/navigation/Navigation";

//Images
import Article16Img from '../../components/images/Article16.png';
import scientificArticlesBg from '../../components/images/scientific-articles-bg.svg';

//Components
import FooterSmall from '../../components/footer/FooterSmall';

const Article16 = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bioplotting for vestigial embryonic development</title>

        <meta
          name="description"
          content="3D-bioprinting has emerged as a potential approach to advance the
          medical field"
        />
      </Helmet>
      <Navigation />
      <Container
        fluid
        className={cn(styles.content, 'p-0')}
        style={{
          backgroundImage: `url(${scientificArticlesBg})`,
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      >
        <Row className="g-0">
          <Col md="8" className="offset-md-2">
            <img src={Article16Img} width="100%" alt="article 16" />
          </Col>
        </Row>
      </Container>

      <FooterSmall />
    </>
  );
};

export default Article16;
