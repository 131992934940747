import * as cn from "classnames";
import * as styles from "./Articles.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Navigation from "../../components/navigation/Navigation";

//Images
import sa9 from "../../components/images/sa-9.png";
import sa81 from "../../components/images/sa-8-1.png";
import galaxy from "../../components/images/galaxy.png";
import scientificArticlesBg from "../../components/images/scientific-articles-bg.svg";

//Components
import FooterSmall from "../../components/footer/FooterSmall";
import { ReadThisNext } from "./ReadThisNext";

const Article7 = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ADVIS Selects Final Crew for Mission to Galeria</title>

        <meta
          name="description"
          content="The NASA-OEM Salvation project will be sending six crew members"
        />
      </Helmet>
      <Navigation />
      <Container
        fluid
        className={cn(styles.content, "p-0")}
        style={{
          backgroundImage: `url(${scientificArticlesBg})`,
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <Row className={cn("g-0", styles.mainHeader)}>
          <Col xs="8" md="6" className="offset-md-2 offset-2">
            <h6 className="mb-5">
              ADVIS Selects Final Crew for Mission to Galeria
            </h6>
            <h4>
              The NASA-OEM Salvation project will be sending six crew members
            </h4>
            <p className={cn(styles.sidebarArticle)}>
              By Martina Salvator on December 15, 2038
            </p>
          </Col>
          <Col md="2">
            <ReadThisNext offset={3}></ReadThisNext>
          </Col>
        </Row>
        <Row className="g-0">
          <Col md="8" className="offset-md-2">
            <img src={sa9} width="100%" alt="article cover" />
            <p className={cn(styles.sidebarArticle)}>
              Simulation of the approach to Galeria
            </p>
            <p>
              Scientists, engineers, and researchers operating under the
              NASA-OEM Salvation project will be sending six crew members
              selected by the OEM (Outlive Earth Movement) team to Galeria in an
              interstellar lightonium rocket to assist with the opening of
              SOULMATES pods that are nothing but an artificial womb chamber.
              They will also be sending 50 mini spaceships, 10 sleeper cells
              known as hibernation pods for the six ADVIS (Automated Distributed
              Visionary Information System) crew members, Artificial Intelligent
              automated robots – Tars and case, Galeria’s civilization project
              house, greenhouse farming project house, transportation equipment,
              medical equipment, flight surgery equipment, asteroid altering
              path GN-DRIVE-GRAVITY device tool, food, and necessary supplies,
              along with Soul pods and six crew members in an interstellar
              lightonium rocket for the civilization project on Galeria.
            </p>

            <p>
              The teams had planned an ADVIS examination test, set by OEM
              members for the selection process of ADVIS crew members. The test
              was fully organized and structured by the OEM team and by Dr.
              Sandra Dono. The following eligibility criteria for the ADVIS test
              were set by the NASA-SpaceX salvation team:
            </p>

            <p>
              A. Undergo and pass a (Simulation serum) serum-induced
              psychological aptitude test.
            </p>
            <p>
              B. Undergo and Pass: diagnostics tests, PR symptomatic and
              predictive tests, carrier tests, pharmacogenetics tests, parental
              tests, new-born screening, preimplantation tests, and DNA tests.
            </p>
            <p>
              C. Should have a Master’s Degree or Ph.D. in a STEM
              (science-technology-engineering-mathematics-specialists) field, in
              any one of the fields of STEM.
            </p>
            <p>D. Should clear NASA’s qualification entrance test.</p>
            <p>
              E. Must have a minimum of 3 years of experience-related
              professional working experience in their field or line of work or
              space flight.
            </p>
            <p>F. An out achiever.</p>
            <p>
              G. Should undergo and pass the SpaceX salvation civilization test.
            </p>

            <p>
              Around 900 candidates were able to clear the eligibility criteria
              set by the NASA-SpaceX salvation team, and they applied for the
              ADVIS test. Only 6 members out of 900 were able to clear the ADVIS
              examination test set up by OEM members.{" "}
            </p>

            <p>
              The six members who were selected are Professor Alfredo Murado,
              Dr. Alejandro Curez, Dr. Charles Obstansa, Marie Odwayo, Professor
              Motoko Kiko, and Sadie O’Harris.{" "}
            </p>

            <p>
              Professor Alfredo Murado, a mechanical engineer and a nuclear
              fusion specialist who has completed four outer space flight
              missions and has experience of 546 days of space flight, was
              appointed to the chief engineer position.{" "}
            </p>

            <img src={sa81} width="100%" alt="article cover" />

            <p>
              Dr. Charles Obstansa has worked for Future SpaceX Expedition95
              and, due to his expert knowledge, qualification, and interstellar
              mission experience, he was selected by the OEM team and NASA for
              the A.D.V.I.S mission to the exoplanet. He was appointed to a
              commander position for the ADVIS mission to Galeria. Dr. Charles
              has years of experience as a “flight surgeon.” He has a doctorate
              and an MD in biochemistry and medicine.
            </p>

            <p>
              Dr. Alejandro Curez revealed in his thesis that the exoplanet
              Trappist-1e/Galeria doesn’t have any hydrogen-dominant
              atmospheres, which indicates the planet’s compatibility and
              hydrogen-free atmosphere during his college years. He was selected
              by NASA in the year 2032 for the NASA SpaceX Crew-99 mission to
              the ISS (international space station). He has more than 3 years of
              space flight experience. Dr. Alejandro has expertise in
              understanding climate or atmospheric patterns, temperature
              variations, and the planet’s atmosphere. Dr. Alejandro Curez was
              appointed to the Specialist position for the ADVIS mission to the
              exoplanet Galeria by the OEM team and NASA.{" "}
            </p>

            <p>
              Marie Odwayo began her professional career at the Johnson Space
              Centre (JSC) in the year 2026 as an engineer and deputy commander
              for the international space shuttle program. She has more than
              five years of training and experience as an AI flight engineer.
              She has expertise in topics like computer engineering, bio-organic
              interfaces, and augmented reality. In the year 2029, Marie
              developed an AlphaBetaFold9 programme that revealed
              protein-folding-molecules-problems. In the year 2032, Marie came
              up with robots and automated software named Tars-X and Case-Y for
              assisting astronauts or engineers on the International Space
              Station. She has worked as an AI engineer, flight engineer, and
              deputy commander with JSC, NASA, and SpaceX. The OEM team and NASA
              appointed her as a Deputy Commander for the ADVIS mission to the
              Galeria.{" "}
            </p>

            <p>
              Professor Motoko Kiko was selected by Nasa (2035) for the
              Survival-Human-Expedition-99-45/54 program. Professor Motoko Kiko
              has worked on the Earth Cimatic-scenario-78 project with famous
              scientist Dr. Khushi Gupta. She was the first person who sequenced
              and structured artificial conscious DNA in space. She has
              expertise in topics like psychotherapy, adaptation, communication,
              meditation, and medicine. Motoko served at the International Space
              Centre for the SpaceX-99-Mars exploration mission. She has more
              than 5 years of training in flight surgery and space flight
              medicine practice. Professor Motoko Kiko was selected for the
              psychologist position for the ADVIS mission to the Galeria by the
              OEM team and NASA.{" "}
            </p>

            <p>
              Sadie O’Harris has more than five years of training as an
              Astro-botanist candidate in outer space. She was in the on-lead
              position on the mission Mars Desert Research Station
              (Utah)-GreenHab. She has also worked in an ISS programme initiated
              by NASA for life support management and water recycling during her
              college years and came up with the innovative idea of building a
              bio-tech-chemical plant that extracts the hydrogen that is found
              in the atmosphere and treats it with chemical plants and oxides to
              generate liquid water. She was appointed to the position of Astro
              botanist for the ADVIS mission to Galeria by the OEM and NASA.
            </p>

            <p>
              It’s the first time in history that NASA and OEM members have
              initiated a civilization mission to Galeria. The interstellar
              lightonium rocket is being tested. Fifteen integrated flight tests
              will take place on December 30th, 2038. The mission to Galeria is
              humanity’s last hope to save humanity and humankind. Deep space
              exploration projects and research have provided the foundation of
              the Galeria mission.
            </p>

            <p>
              NASA-OEM is targeting Monday, November 25th, 2040 for the launch
              of SOULS pods with six ADVIS crew members on an interstellar
              Lightonium Rocket. Two SpaceX automated probes are also set for
              launch to the Galeria to observe the planet’s atmosphere, climate,
              geological structure, and safety zone for the successful landing
              of the interstellar lightonium rocket on the surface of the
              Galeria.
            </p>
            <img src={galaxy} width="100%" className="my-5" alt="galaxy" />
          </Col>
        </Row>
      </Container>

      <FooterSmall />
    </>
  );
};

export default Article7;
