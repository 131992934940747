import * as cn from "classnames";
import * as styles from "./Home.module.scss";
import { Container, Button, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useSwipeable } from "react-swipeable";
import React, { useState, useEffect, useRef } from "react";

//import AwesomeSlider from "react-awesome-slider";
//import 'react-awesome-slider/dist/styles.css';

import Navigation from "../../components/navigation/Navigation";
//Image assets
import homeDroneshot from "../../components/images/Droneshot_Static_firstframe.png";
import homeBg2 from "../../components/images/home-bg-2.png";
import homeBg3 from "../../components/images/home-bg-3.png";
import homeBg4 from "../../components/images/home-bg-4.png";
import AttributeSoul from "../../components/images/AttributeSoul.png";
import soulTurquoise from "../../components/images/soul-turquoise.png";
import soulSalmon3d from "../../components/images/soul-salmon.png";
import soulGrey from "../../components/images/soul-grey.png";
import SalmonSphere from "../../components/images/sphere-salmon.png";

import redPod from "../../components/images/red-pod.png";
import soulRedRight from "../../components/images/soul-red-right.png";
import phase1Icon from "../../components/images/phase-1-icon.png";
import phase2Icon from "../../components/images/phase-2-icon.png";
import phase3Icon from "../../components/images/phase-3-icon.png";
import mitGroup from "../../components/images/mit-group.png";
import teamMember1 from "../../components/images/team-member-1.png";
import teamMember2 from "../../components/images/team-member-2.png";
import teamMember3 from "../../components/images/team-member-3.png";
import teamMember4 from "../../components/images/team-member-4.png";
import teamMember5 from "../../components/images/team-member-5.png";
import switchGraphic from "../../components/images/switch.svg";

//Components
import Footer from "../../components/footer/Footer";
import { VideoContainer } from "../../components/VideoContainer";

import Carousel, { CarouselItem } from "../../components/carousel/Carousel";
import { isNil, map } from "lodash";
import { useLocation } from "react-router-dom";
import { Pod } from "../../components/pod/Pod";

const homepageStyles = {
  // display: "None",
};

const homesouls = [
  { source: "homepage/soulmate_001.png" },
  { source: "homepage/soulmate_002.png" },
  { source: "homepage/soulmate_005.png" },
  { source: "homepage/soulmate_008.png" },
  { source: "homepage/soulmate_011.png" },
  { source: "homepage/soulmate_013.png" },
  { source: "homepage/soulmate_014.png" },
  { source: "homepage/soulmate_021.png" },
  { source: "homepage/soulmate_028.png" },
];

const Home = (props) => {
  const missionControlRef = useRef();
  const roadmapRef = useRef();
  const location = useLocation();

  // console.log('location', location)
  useEffect(() => {
    // console.log('location', location)
    if (!isNil(location.state)) {
      if (location.state.missionControl) {
        setTimeout(() => {
          missionControlRef.current.scrollIntoView({
            behavior: "smooth",
          });
        }, 500);
      } else if (location.state.roadmap) {
        setTimeout(() => {
          roadmapRef.current.scrollIntoView({
            behavior: "smooth",
          });
        }, 500);
      }
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Soulmates - Home</title>
        <link rel="canonical" href="http://soulmatesmission.com" />
        <meta name="description" content="Soulmates - Home" />
      </Helmet>
      <Navigation />
      <VideoContainer
        mp4_link={`${process.env.PUBLIC_URL}/droneshot.mp4`}
        poster={`${homeDroneshot}`}
      >
        <div className={cn(styles.h1_backdrop)}>
          <h1 className={cn(styles.video_h1, "text-white text-center mt-3")}>
            What if Earth was dying, <br /> but Humanity could survive?
          </h1>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <a href="https://app.heymint.xyz/soulmates/">
            <Button variant="secondary" className="me-2">
              Whitelist
            </Button>
          </a>
        </div>
        <Carousel className={styles.home_carousel}>
          {map(homesouls, (soul) => {
            return (
              <CarouselItem key={soul.source}>
                <img src={soul.source} className={styles.img_carousel_item} />
              </CarouselItem>
            );
          })}
        </Carousel>
      </VideoContainer>
      <Container
        fluid
        className="p-0"
        style={{
          backgroundColor: "#2C9CFF",
          ...homepageStyles,
        }}
      >
        <Row className="g-0">
          <Col
            xs="6"
            md="4"
            className={cn("offset-1 offset-md-3", styles.textSection)}
          >
            <h1 className="text-white text-md-end">MEET THE S.O.U.L.S.</h1>
            <p className="text-white text-md-end">
              2,080 PODS, sent from Earth to the planet Galeria to hatch, match,
              and evolve in order to re-populate Earth in the future.
            </p>

            <p className="text-white text-md-end">
              The four different pod types have been scientifically optimized to
              have the best chances at surviving in Galeria’s environments,
              covering Air, Earth, Water, and Fire.
            </p>

            <p className="text-white text-md-end">
              By inputting into the SOULS algorithm prior to hatching, their
              personalities will be shaped by those still left on Earth to
              create the most optimal matching and mating process to ensure
              generational survival.
            </p>
          </Col>
          <Col xs="4" md="3">
            <img
              className={styles.flip}
              src={soulRedRight}
              alt="Red Soul"
              width="80%"
            />
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className={cn("p-0", styles.section)}
        style={{
          backgroundColor: "#FFF",
          ...homepageStyles,
        }}
      >
        <Row className="g-0 position-relative">
          <Pod
            side="left"
            variant="blue"
            width="70%"
            xoffset="50px"
            top="-100px"
          />
          <Pod side="right" variant="yellow" />
          <Col
            xs="10"
            md="4"
            className={cn(
              "offset-1 offset-md-4 position-relative",
              styles.textSection
            )}
          >
            <h1 className="text-black-50 text-center">CLAIM YOUR P.O.D.</h1>
            <p className="text-black-50 text-center">
              {" "}
              Mint your POD to join the mission to Galeria.{" "}
            </p>

            <p className="text-black-50 text-center">
              Each Pod contains a SOUL, waiting to hatch on Galeria and on
              minting, the SOUL's personality is formed using input from you!
            </p>

            <p className="text-black-50 text-center">
              There are 17 personality factors which combine to create your one
              of a kind POD which will hatch into an adult SOUL.
            </p>

            <p className="text-black-50 text-center">
              Each adult SOUL has nine attributes (background, body, arms, legs,
              ears, head, mouth, eyes, and texture).
            </p>

            <p className="text-black-50 text-center">
              The organization ADVIS (Automated Distributed Visionary
              Information System) has designed the SOULs with a large variety of
              different features to maximize their chances of survival within
              Galeria’s various environments.
            </p>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className={cn("p-0", styles.section)}
        style={{
          backgroundColor: "#2C9CFF",
          ...homepageStyles,
        }}
      >
        <Row className="g-0 position-relative">
          <Pod side="left" variant="salmon" top="-200px" />
          <Col
            xs="6"
            md="5"
            className={cn("offset-1 offset-md-2", styles.textSection)}
          >
            <h1 className="text-white text-md-end">FIND YOUR M.A.T.E.S.</h1>
            <p className="text-white text-md-end">
              The first generation (Origin Collection) will consist of 2,080
              SOULS, with the possibility of 1,040 MATES (matching algorithm to
              ensure survivability.
            </p>

            <p className="text-white text-md-end">
              The stewards of these SOULS will need to find their predestined
              match in order to unlock the ability to create the next generation
              together, in a unique cooperative social experiment.
            </p>

            <p className="text-white text-md-end">
              Each mint would provide the owner with 1 SOUL POD and the matching
              mechanism would guide the search until all SOULs have a match.
            </p>
            <p className="text-white text-md-end">
              Each SOUL will need to find their predestined match in order to
              unlock the ability to create the next generation together in a
              unique cooperative social experiment.
            </p>
          </Col>
          <Col xs="5" md="4" className={styles.soul_grey_wrapper}>
            <img src={soulGrey} alt="Grey Soul" className={styles.soul_grey} />

            <div className={styles.grey_wrapper}></div>
            {/* <img src={soulGrey} alt="Grey Soul" width="100%" /> */}
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className={cn("p-0", styles.section)}
        style={{
          backgroundImage: `url(${homeBg2})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          ...homepageStyles,
        }}
      >
        <Row
          className="g-0"
          style={{
            height: "100%",
            backgroundColor: "rgb(35 74 232 / 56%)",
          }}
        >
          <Col
            xs="10"
            md="6"
            className={cn("offset-1 offset-md-3", styles.textSection)}
          >
            <h1 className="text-white text-center">GALERIA</h1>
            <p className="text-white text-center">
              The discovery of Trappist-1e was revealed on 22 February 2017 and
              in 2031 was renamed Galeria after a global competition.
            </p>

            <p className="text-white text-center">
              Galeria is around 40 light-years from Earth and orbits around the
              ultracool dwarf star TRAPPIST-1. It is thought to be close Earth’s
              temperature, mass, radius, density, and gravity. Galeria has 93%
              the surface gravity of Earth and an equilibrium temperature of
              246.1 K.
            </p>

            <p className="text-white text-center">
              By 2040, the technology to launch an interstellar lightonium
              rocket had been established, opening up the opportunity to safely
              send a exploration party.
            </p>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <Button variant="secondary">Explore Galeria</Button>
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className={cn("p-0", styles.section)}
        style={{
          backgroundColor: "#FFF",
          ...homepageStyles,
        }}
      >
        <Row className="g-0">
          <Pod side="left" variant="yellow" xoffset="50px" />
          <Pod side="right" variant="blue" />
          <Col
            xs="10"
            md="6"
            className={cn("offset-1 offset-md-3", styles.textSection)}
          >
            <h1 className="text-black-50 text-center">O.E.M. & A.D.V.I.S.</h1>
            <p className="text-black-50 text-center">
              The Outlive Earth Movement (OEM) was founded in 2018 with the goal
              of finding an alternative path to saving humanity should the
              efforts to reverse climate change fail. OEM is responsible for
              some of the most significant scientific advances of the past three
              decades, including the creation of the technology that for the
              Souls. Dr. Sandra Dono is the current head of the OEM, after
              joining the group during her dissertation research at MIT.
            </p>

            <div className="d-flex align-items-center justify-content-center my-5">
              <img src={mitGroup} width="80%" alt="MIT Group" />
            </div>

            <p className="text-black-50 text-center">
              Closely affiliated with OEM, ADVIS, the group behind the
              development of the automated distributed visionary information
              system, which enables sentiance for the SOULS is comprised of the
              finest technical minds of several generations. More importantly,
              six members of ADVIS have voluntered to travel to Galeria in order
              to establish a base camp there and faciliate the hatching of the
              SOUL pods to improve the chances of the entire missions success.
            </p>

            <p className="text-black-50 text-center">
              Over the long term, OEM hopes that what the Souls learn on Galeria
              can be used to create technology which is sent back to Earth in
              order to restore it’s habitability. Along with the Souls, OEM sent
              a bank of human embryos to Galeria that could one day be used to
              re-populate humankind back on Earth…
            </p>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className={cn("p-0", styles.section)}
        style={{
          backgroundColor: "#2C9CFF",
          ...homepageStyles,
        }}
      >
        <Row className="g-0">
          <Pod side="left" variant="blue" top="-300px" />
          <Col
            xs="10"
            md="8"
            className={cn("offset-1 offset-md-2 mb-0", styles.textSection)}
          >
            <h1 className="text-white text-center">
              S.O.U.L.S Personality,
              <br /> Attributes & Rarity
            </h1>
            <Row className="g-0 mt-3">
              <Col xs="8" md="4" className="offset-2 offset-md-0">
                <p className="text-white text-center">
                  SOULs will mature on chain, growing from their PODs into their
                  Adult forms. Mating will happen in conjuction with other SOUL
                  holders.
                </p>
              </Col>
              <Col xs="8" md="4" className="offset-2 offset-md-0">
                <p className="text-white text-center">
                  A unique one of a kind personality creation quiz that will
                  happen on chain during mint, allowing personalization of each
                  SOUL.
                </p>
              </Col>
              <Col xs="8" md="4" className="offset-2 offset-md-0">
                <p className="text-white text-center">
                  Rare SOULs who match with other rare SOULs with create the
                  most powerful future generations with enhanced ablities.
                </p>
              </Col>
            </Row>
          </Col>
          <div className="d-flex justify-content-center">
            <img src={AttributeSoul} alt="soul attributes" width="70%" />
          </div>
        </Row>
      </Container>
      <Container
        fluid
        className={cn("p-0", styles.section)}
        style={{
          backgroundImage: `url(${homeBg3})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          ...homepageStyles,
        }}
      >
        <Row id="roadmap" ref={roadmapRef} className="g-0">
          <Col
            xs="12"
            md="12"
            className={cn(
              "offset-0 offset-md-0",
              styles.textSection,
              styles.roadmap
            )}
          >
            <h1 className="text-center">ROADMAP TO GALERIA</h1>
            <div className={cn("d-flex justify-content-space-between")}>
              <div className={cn("mt-3", styles.roadmapFlex)}>
                <h6 className="text-end ">Phase 1:</h6>
                <h5 className="text-end">
                  Origin Collection
                  <br />
                  (2,080 total mint)
                </h5>

                <h5 className="text-end">Presale</h5>
                <p className="text-end">
                  Matching attribute quiz to feed mint
                  <br />
                  Sample 3D model of 2 Adult Soulmates
                  <br />
                  2D models created Baby Soulmates in Pods
                  <br />
                  2D models created Adult Soulmates
                </p>

                <h5 className="text-end">25%</h5>
                <p className="text-end">
                  Giveaway for all current Soulmates Origin holders <br />
                  Gaming advisor added to team
                </p>

                <h5 className="text-end">50%</h5>
                <p className="text-end">
                  Generational aging unlocked (POD&nbsp;{"->"}&nbsp;ADULT)
                  <br />
                  2D models distributed ADULT Soulmates
                </p>

                <h5 className="text-end">75%</h5>
                <p className="text-end">
                  AI module unlocked enabling you to interact with your SOUL
                </p>

                <h5 className="text-end">100%</h5>
                <p className="text-end">
                  3D models for ADULTS created for future GaleraVerse
                  <br />
                  Matching mechanism released
                  <br />
                  Phase 2 expansion 2D model examples created
                  <br />
                  Audio podcast script created for “Mission to Galeria”
                </p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={phase1Icon} alt="phase 1 Icon" width="55%" />
                <hr width="1" size="640" className="mx-4" />
              </div>

              <div
                className={cn(
                  styles.roadmapFlex,
                  "d-flex justify-content-center align-items-center"
                )}
              >
                <img src={redPod} alt="red pod" width="100%" />
              </div>
            </div>
            <div className="d-flex justify-content-space-between">
              <div className={cn(styles.roadmapFlex)}>
                <img
                  className={cn(styles.soulRedRight, styles.flip)}
                  src={soulSalmon3d}
                  alt="Red Soul"
                />
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={phase2Icon} alt="phase 2 Icon" width="55%" />
                <hr width="1" size="700" className="mx-4" />
              </div>

              <div className={cn(styles.roadmapFlex)}>
                <h6 className="text-start">Phase 2:</h6>
                <h5 className="text-start">
                  Expansion Collection (8,000 total mint)
                </h5>

                <h5 className="text-start">Presale</h5>
                <p className="text-start">2D models created</p>

                <h5 className="text-start">25%</h5>
                <p className="text-start">
                  Giveaway for all current Soulmates Expansion holders
                  <br />
                  “Mission to Galeria” audio podcast produced & released
                </p>

                <h5 className="text-start">50%</h5>
                <p className="text-start">
                  Generational aging unlocked (POD, ADULT, Senior)
                  <br />
                  AI module upgrade enabling you to interact with your SOUL as
                  well as SOULS to interact with their MATES
                  <br />
                  Staking mechanism released
                </p>

                <h5 className="text-start">75%</h5>
                <p className="text-start">
                  Physical 3D models created for Origin Creation
                  <br />
                  Robot companions / equipment released for Origin &
                  <br />
                  Expansion via airdrop
                  <br /> Additional gaming advisors recruited
                </p>

                <h5 className="text-start">100%</h5>
                <p className="text-start">
                  3D models created for future use
                  <br /> Soulmates mobile gaming experience designed & launched
                  <br />
                  IRL meet-up planned
                  <br /> First collaborative module created
                  <br />
                  Generative AI based art created between MATES
                  <br /> Graphic novel produced & released
                </p>
              </div>
            </div>
            <div className={cn("d-flex justify-content-space-between")}>
              <div className={cn(styles.roadmapFlex)}>
                <h6 className="text-end">Phase 3:</h6>
                <h5 className="text-end">GaleriaVerse</h5>

                <p className="text-end">
                  Soulmates and treasury deployed across metaverse into land
                  sales and community pitche projects
                </p>

                <p className="text-end">
                  Soulmates and Galeria IP developed into further entertainment
                  concepts (novels, podcasts, series)
                </p>

                <p className="text-end">Soulmates gaming expanded</p>
              </div>
              <div className="d-flex flex-column align-items-center">
                <img src={phase3Icon} alt="phase 1 Icon" width="55%" />
              </div>

              <div className={cn(styles.roadmapFlex)}></div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className={cn("p-0", styles.section)}
        style={{
          backgroundColor: "rgb(237 245 253)",
          ...homepageStyles,
        }}
      >
        <Row className="g-0">
          <Pod
            side="left"
            variant="salmon"
            xoffset="80px"
            top="-140px"
            width="70%"
          />
          <Col
            xs="10"
            md="6"
            className={cn(
              "offset-1",
              "offset-md-3",
              styles.textSection,
              styles.missionControl
            )}
          >
            <h1 className={cn("text-center")}>
              <a id="mission-control" ref={missionControlRef}>
                MISSION CONTROL
              </a>
            </h1>
            <div className="d-flex mt-3">
              <div className="me-5">
                <img src={teamMember1} alt="Amber Harris" width="80px" />
              </div>
              <div>
                <h6>Amber Baris</h6>
                <h5>Artist</h5>
                <p>
                  Amber is a 3D Artist and CGI Generalist that has a degree in
                  Computer Animation. She is currently working on feature films
                  as a Previsualization artist at Technicolor. <br /> Website |
                  Twitter
                </p>
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="me-5">
                <img src={teamMember2} alt="Amber Harris" width="80px" />
              </div>
              <div>
                <h6>Mike Weber</h6>
                <h5>Marketing & co-founder</h5>
                <p>
                  Mike is a branding expert, data-inspired strategist,
                  storyteller, and c-suite advisor with more than 15 years
                  consulting experience. He’s successfully built and led
                  agencies, analytics practices, and multi-functional client
                  consulting teams across the world. <br />
                  Twitter
                </p>
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="me-5">
                <img src={teamMember4} alt="Amber Harris" width="80px" />
              </div>
              <div>
                <h6>Peter Conerly</h6>
                <h5>Web3 Developer</h5>
                <p>
                  Peter majored in biochemistry, and switched to software
                  engineering with the help of his computer science brother.
                  He’s spent a decade doing web development and enjoys making
                  tools that help people solve problems. In his spare time,
                  Peter hikes, runs, weightlifts and plays FPS games. <br />{" "}
                  Website
                </p>
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="me-5">
                <img src={teamMember5} alt="Amber Harris" width="80px" />
              </div>
              <div>
                <h6>Ryan Chanatry</h6>
                <h5>Creator & co-founder</h5>
                <p>
                  Ryan is an Academy Award & Emmy nominated Executive Producer,
                  having overseen more than forty series, films, and shorts. His
                  experience spans entertainment, media, marketing, and
                  analytics. <br /> Website | Twitter
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className={cn("p-0", styles.section)}
        style={{
          backgroundImage: `url(${homeBg4})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          ...homepageStyles,
        }}
      >
        <Row className="g-0">
          <Pod side="right" variant="blue" top="-80px" width="70%" />

          <Col
            xs="10"
            md="6"
            className={cn(
              "offset-1 offset-md-3",
              styles.textSection,
              styles.joinUs
            )}
          >
            <h1 className="text-center">WHY JOIN US</h1>
            <h5 className="text-center">
              S.O.U.L+M.A.T.E.S. <br />
              is pushing the boundaries in a number of ways:
            </h5>
            <div className="d-flex">
              <div className="me-4">
                <img src={switchGraphic} alt="Active Switch" width="50px" />
              </div>
              <div>
                <p>
                  Prior to mint, your answers to our Personality quiz will
                  directly shape your SOUL and their matchability with their
                  future MATE
                </p>
              </div>
            </div>
            <div className="d-flex">
              <div className="me-4">
                <img src={switchGraphic} alt="Active Switch" width="50px" />
              </div>
              <div>
                <p>
                  Origin holders will share in some form in the success of all
                  future SOUL generations
                </p>
              </div>
            </div>
            <div className="d-flex">
              <div className="me-4">
                <img src={switchGraphic} alt="Active Switch" width="50px" />
              </div>
              <div>
                <p>
                  The mating of SOULS requires the participation of two separate
                  wallet holders and their interactions potentially determines
                  the level of future success of the lineage
                </p>
              </div>
            </div>
            <div className="d-flex">
              <div className="me-4">
                <img src={switchGraphic} alt="Active Switch" width="50px" />
              </div>
              <div>
                <p>
                  SOULS will evolve on the blockchain, with each holders
                  individual and cooperative activities affecting their
                  characteristic scores over time, with the goal of creating the
                  strongest lineages
                </p>
              </div>
            </div>
            <div className="d-flex">
              <div className="me-4">
                <img src={switchGraphic} alt="Active Switch" width="50px" />
              </div>
              <div>
                <p>
                  A future community based platform will be developed allowing
                  anyone to create additional activities (art, gaming, etc) for
                  SOULS to participate in and outside the metaverse
                  (Decentraland, Aether, NETVRK, Sandbox, etc)
                </p>
              </div>
            </div>
          </Col>
          <Pod
            side="left"
            variant="yellow"
            width="90%"
            bottom="-120px"
            bottomattached={true}
          />
        </Row>
      </Container>
      <Footer
        introText={
          <p className="text-color-soul text-center">
            We can’t wait to bring the story of Soulmates into the world. We
            believe our unique approach to the NFT and smart contract space will
            push the boundaries of what is possible while creating and rewarding
            our community over the long-term. We hope you join us on Galeria.
          </p>
        }
      />
    </>
  );
};

export default Home;
