export const colorOptions = [
  '/souls/attributes/colors/F2.png',
  '/souls/attributes/colors/F3.png',
  '/souls/attributes/colors/F4.png',
  '/souls/attributes/colors/F5.png',
  '/souls/attributes/colors/F6.png',
  '/souls/attributes/colors/G1.png',
  '/souls/attributes/colors/G2.png',
  '/souls/attributes/colors/G3.png',
  '/souls/attributes/colors/H1.png',
  '/souls/attributes/colors/H2.png',
  '/souls/attributes/colors/H3.png',
  '/souls/attributes/colors/H4.png',
  '/souls/attributes/colors/H5.png',
  '/souls/attributes/colors/H6.png',
  '/souls/attributes/colors/I1.png',
  '/souls/attributes/colors/I2.png',
  '/souls/attributes/colors/I3.png',
  '/souls/attributes/colors/I4.png',
  '/souls/attributes/colors/I5.png',
  '/souls/attributes/colors/I6.png',
];

export const armOptions = [
  '/souls/attributes/arms/A1.png',
  '/souls/attributes/arms/A2.png',
  '/souls/attributes/arms/A3.png',
  '/souls/attributes/arms/A4.png',
  '/souls/attributes/arms/A5.png',
  '/souls/attributes/arms/A6.png',
  '/souls/attributes/arms/A7.png',
];

export const earOptions = [
  '/souls/attributes/ears/C1.png',
  '/souls/attributes/ears/C2.png',
  '/souls/attributes/ears/C3.png',
  '/souls/attributes/ears/C4.png',
  '/souls/attributes/ears/C5.png',
  '/souls/attributes/ears/C6.png',
  '/souls/attributes/ears/C7.png',
  '/souls/attributes/ears/C8.png',
  '/souls/attributes/ears/C9.png',
  '/souls/attributes/ears/C10.png',
  '/souls/attributes/ears/C11.png',
  '/souls/attributes/ears/C12.png',
];

export const legOptions = [
  '/souls/attributes/legs/B1.png',
  '/souls/attributes/legs/B2.png',
  '/souls/attributes/legs/B3.png',
  '/souls/attributes/legs/B4.png',
  '/souls/attributes/legs/B5.png',
  '/souls/attributes/legs/B6.png',
  '/souls/attributes/legs/B7.png',
  '/souls/attributes/legs/B8.png',
];

export const textureOptions = [
  '/souls/attributes/textures/J1.png',
  '/souls/attributes/textures/J2.png',
  '/souls/attributes/textures/J3.png',
  '/souls/attributes/textures/J4.png',
  '/souls/attributes/textures/J5.png',
  '/souls/attributes/textures/J6.png',
  '/souls/attributes/textures/J7.png',
  '/souls/attributes/textures/J8.png',
  '/souls/attributes/textures/J9.png',
  '/souls/attributes/textures/J10.png',
  '/souls/attributes/textures/J11.png',
  '/souls/attributes/textures/J12.png',
  '/souls/attributes/textures/J13.png',
  '/souls/attributes/textures/J14.png',
  '/souls/attributes/textures/J15.png',
  '/souls/attributes/textures/J16.png',
  '/souls/attributes/textures/J17.png',
];
