import { Nav } from "react-bootstrap";
import * as cn from "classnames";
import * as styles from "./Articles.module.scss";

export const ReadThisNext = (props) => {
  const { offset } = props;
  // choose 3/4 articles to sidebar
  const articlelinks = [
    <Nav.Link
      key={8}
      className={cn(styles.sidebarArticle)}
      href="/scientific-articles/6/GAM Announces the Creation of Automated Distributed Visionary Information System"
    >
      GAM announces the creation of Automated Distributed Visionary Information
      System
    </Nav.Link>,
    <Nav.Link
      key={9}
      className={cn(styles.sidebarArticle)}
      href="/scientific-articles/7/ADVIS-Selects-Final-Crew-for-Mission-to-Galeria"
    >
      ADVIS Selects Final Crew for Mission to Galeria
    </Nav.Link>,
    <Nav.Link
      key={5}
      className={cn(styles.sidebarArticle)}
      href="/scientific-articles/8/Code-RED-declared-for-Humanity-as-Earth’s-Expiration-Date-is-Determined"
    >
      Code RED declared for Humanity as Earth’s Expiration Date is Determined
    </Nav.Link>,
    <Nav.Link
      key={6}
      className={cn(styles.sidebarArticle)}
      href="/scientific-articles/9/Winner-of-Competition-for-the-New-Name-of-TRAPPIST-1E-Named"
    >
      Winner of Competition for the New Name of TRAPPIST-1E Named
    </Nav.Link>,
    <Nav.Link
      key={10}
      className={cn(styles.sidebarArticle)}
      href="/scientific-articles/10/Successful-Launch-of-the-Interstellar-Lightonium-Rocket-to-Galeria"
    >
      Successful Launch of the Interstellar Lightonium Rocket to Galeria
    </Nav.Link>,
    <Nav.Link
      key={11}
      className={cn(styles.sidebarArticle)}
      href="/scientific-articles/11/ADVIS-Lands-on-Galeria-after-39-Year-Journey-Across-Universe"
    >
      ADVIS Lands on Galeria after 39 Year Journey Across Universe
    </Nav.Link>,
    <Nav.Link
      key={4}
      className={cn(styles.sidebarArticle)}
      href="/scientific-articles/12/OEM-Embroiled-In-Latest-Controversy-After-Mysterious-Murder"
    >
      OEM Embroiled In Latest Controversy After Mysterious Murder
    </Nav.Link>,
    <Nav.Link
      key={7}
      className={cn(styles.sidebarArticle)}
      href="/scientific-articles/13/Game-Changing-Artificial-Gestation-Technology-Passes-Final-Hurdles"
    >
      Game Changing Artificial Gestation Technology Passes Final Hurdles
    </Nav.Link>,
    <Nav.Link
      key={1}
      className={cn(styles.sidebarArticle)}
      href="/scientific-articles/15/Brain-inspired-cognitive-systems"
    >
      Brain-inspired cognitive systems
    </Nav.Link>,
    <Nav.Link
      key={2}
      className={cn(styles.sidebarArticle)}
      href="/scientific-articles/16/Bioplotting-for-vestigial-embryonic-development"
    >
      Bioplotting for vestigial embryonic development
    </Nav.Link>,
    <Nav.Link
      key={3}
      className={cn(styles.sidebarArticle)}
      href="/scientific-articles/17/Earth-Like-Planets-Found-by-NASA"
    >
      Earth Like Planets Found by NASA; Offer Hope for Future of Humanity
    </Nav.Link>,
  ];

  let theselinks = articlelinks.slice(offset, offset + 3);
  theselinks = theselinks.reduce((prev, curr, i) => {
    prev.push(curr);
    if (i !== theselinks.length - 1) {
      prev.push(<hr></hr>);
    }
    return prev;
  }, []);
  // console.log("theselinks", theselinks);
  return (
    <>
      <h5>READ THIS NEXT</h5>
      {theselinks}
    </>
  );
};
