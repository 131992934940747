import { useEffect, useState } from "react";

export const useSigner = (provider) => {
  const [signer, setSigner] = useState();
  const updateSigner = () => {
    if (!!provider) {
      setSigner(provider.getSigner());
    }
  };
  useEffect(updateSigner, [provider]);

  return [signer, updateSigner];
};
